/*
 ***********************************************************************************************************************************
 *
 * Load all js dependencies
 *
 * Eseguire npm update
 *
 * Eseguire yarn add modulo per aggiungere nuovi moduli
 * Eseguire npm run dev ogni volta che si aggiorna questo file IN DEV
 * Eseguire npm run prod ogni volta che si aggiorna questo file IN PROD
 *
 ***********************************************************************************************************************************
 */

//Moment.js
import mm from 'moment-timezone';
window.moment = mm;
moment.locale('it');
moment.tz('Europe/Rome');

//Datetimepicker: //https://xdsoft.net/jqplugins/datetimepicker/
window.datetimepicker = require('jquery-datetimepicker/build/jquery.datetimepicker.full.min');

//I18n
import I18n from './vendor/I18n.js';
window.I18n = I18n;
window.translator = new I18n;

//Jquery validation
import jqv from 'jquery-validation';
window.jvalidate = jqv;

//Jquery UI
import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/sortable.js';

//Jquery confirm: https://craftpip.github.io/jquery-confirm/
window.confirm = require('jquery-confirm/dist/jquery-confirm.min');
import 'jquery-confirm/dist/jquery-confirm.min.css';

//Colorpicker: https://github.com/Simonwep/pickr
window.Pickr = require('@simonwep/pickr/dist/pickr.min');

//Jquery slugify
window.slugify = require('@sindresorhus/slugify');

//Jquery confirm: https://craftpip.github.io/jquery-confirm/
window.confirm = require('jquery-confirm/dist/jquery-confirm.min');
import 'jquery-confirm/dist/jquery-confirm.min.css';

//Bootstrap multiselect
window.bsMultiSelect = require('@dashboardcode/bsmultiselect/dist/js/BsMultiSelect.min');
import '@dashboardcode/bsmultiselect/dist/css/BsMultiSelect.min.css';

//Select2
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';

//TyppyJs CSS
//import { animateFill } from 'tippy.js';   // Viene importato in bootstrap.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/material.css';

// AOS CSS Animator
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

// OWL Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

//Datatable plugins
//window.$.DataTable = require('datatables.net-bs4');
//window.$.DataTable = require('datatables.net');
window.$.DataTable = require('datatables.net-bs5');

//window.rowreorder = require('datatables.net-rowreorder');
window.rowreorder = require('datatables.net-rowreorder-bs5');
// Carico un po' di plugin DataTable per BS5 che potrebbero far comodo:
/*require('jszip');
require('pdfmake');
require('datatables.net-bs5')();
require('datatables.net-autofill-bs5')();
require('datatables.net-buttons-bs5')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.print.js')();
require('datatables.net-colreorder-bs5')();
require('datatables.net-fixedcolumns-bs5')();
require('datatables.net-fixedheader-bs5')();
require('datatables.net-responsive-bs5')();*/

// TinyMCE
import tinymce from 'tinymce';

//Custom classes
import formUtilities from '../../public/js/admin/imports/forms.js'; //Utilities form
import utilitiesFunctions from '../../public/js/admin/imports/utilities.js';
import postSuccessFunctions from '../../public/js/admin/imports/postSuccessFunctions.js';
import editorFunctions from '../../public/js/admin/imports/texteditor.js';
import lapeUtilitiesFunctions from '../../public/js/admin/imports/lape_utilities.js'; //Utilities lape

window.ff = new formUtilities();
window.pf = new postSuccessFunctions();
window.te = new editorFunctions();
window.uf = new utilitiesFunctions();
window.lape = new lapeUtilitiesFunctions();
