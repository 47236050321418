$(function () {
    /*
    =============================================================================================================

    oooooooooo.   .oooooo..o       .o      ooooo   ooooo       .o.         .oooooo.   oooo    oooo  .oooooo..o
    `888'   `Y8b d8P'    `Y8     .d88      `888'   `888'      .888.       d8P'  `Y8b  `888   .8P'  d8P'    `Y8
     888     888 Y88bo.        .d'888       888     888      .8"888.     888           888  d8'    Y88bo.
     888oooo888'  `"Y8888o.  .d'  888       888ooooo888     .8' `888.    888           88888[       `"Y8888o.
     888    `88b      `"Y88b 88ooo888oo     888     888    .88ooo8888.   888           888`88b.         `"Y88b
     888    .88P oo     .d8P      888       888     888   .8'     `888.  `88b    ooo   888  `88b.  oo     .d8P
    o888bood8P'  8""88888P'      o888o     o888o   o888o o88o     o8888o  `Y8bood8P'  o888o  o888o 8""88888P'

    =============================================================================================================
    */

    /*
    ********************************************************************************
    *
    * DROPDOWN MULTILEVEL MENU
    *
    ********************************************************************************
    */

    $("ul.dropdown-menu [data-bs-toggle='dropdown']").on("click", function (event) {
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings().toggleClass("show");


        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

    });

})
