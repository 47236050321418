/*
|=================================================================================================================================
|
| FUNZIONI UTILI PER LE FORMS
|
|=================================================================================================================================
*/

import { visitNode } from "typescript";

class formUtilities {
    //Funzione che abilita campi compilabili, li svuota e aggiunge dei bordi
    //Passo array di classi, per ognuna delle qualli scorro i campi ad essa associati e li abilito
    //Params:
    //bool enable: se true, abilito, altrimenti disabilito
    //array classi: array di classi da controllare
    //Se clearValue = true, pulisco il valore del campo
    enableDisabelFields(enable, classi) {
        //console.log(enable);
        $.each(classi, function (index, value, clearValue = false) {
            $("." + value).each(function (item) {
                if (enable == "true") {
                    $(this).prop("disabled", false);
                    $(this).addClass("border border-success");
                    if (clearValue) {
                        $(this).val("");
                    }
                } else {
                    $(this).prop("disabled", true);
                    $(this).removeClass("border border-success");
                    if (clearValue) {
                        $(this).val("");
                    }
                }
            });
        });
    }

    //Controllo che il campo sia numerico
    checkIsNumeric(fieldId) {
        var success = $.isNumeric($("#" + fieldId).val());

        if (!success) {
            setTimeout(function () {
                $("#" + fieldId).focus();
            }, 100);
            $("#" + fieldId)
                .val("")
                .attr("placeholder", "Inserire valore numerico");
            return true;
        }
        return false;
    }

    //Valida una form
    validateForm(formId, cssRules, rules, messages) {
        var form = $("#" + formId);
        // console.log(formId);
        // console.log(form);
        var hasTab = $("#" + formId).attr("data-hastab");

        //console.log(form);

        $.validator.addMethod(
            "valueNotEquals",
            function (value, element, arg) {
                return arg !== value;
            },
            "Value must not equal arg."
        );

        /*
        $.validator.addMethod("selectValueNotEquals", function(value, element, arg){
            alert(value);
            console.log(element);
            return arg != jQuery(element).find('option:selected').text();
        }, 'Select value must not equal arg.');
        */

        $.validator.addClassRules(cssRules);

        form.validate({
            debug: true,
            ignore: ".ignore", //Durante la validazione ignora i campi con classe "ignore"
            errorElement: "span",
            errorClass: "error",
            focusInvalid: true,
            onfocusout: false,
            rules: rules,
            messages: messages,
            /*
            errorPlacement: function () {},
            */
            /*
            submitHandler: function() {
                alert('Successfully saved!');
            },
            */
            /*
            errorPlacement: function(error, element) {
                error.appendTo("#errorbox-container");
              },
              */

            invalidHandler: function (form, validator) {
                setTimeout(function () {
                    var errors = validator.numberOfInvalids();
                    var firstError = validator.errorList[0].element.id;

                    //Pulisco eventuali errori precedenti
                    $("#errorbox-container").html("");

                    //Se sono in una modale, scrolltop si deve riferire al div della modale che carica il contenuto
                    var modalBody = $(".modal-body");
                    //console.log(modalBody.length);

                    if (errors) {
                        //console.log(firstError);

                        //validator.errorList[0].element.focus();
                        //Check offset a seconda che siamo in una modale o in una finestra normale

                        //Come toppoint uso errorbox perchè in caso il campo sia nascosto come in alcune form, genera errore
                        var topPoint =
                            modalBody.length > 0 ? modalBody : $("#errorbox"); //$('#' + firstError); //$('input[id="' + firstError + '"]');

                        var parentElement =
                            modalBody.length > 0 ? ".modal-body" : "html,body";

                        //console.log(topPoint.offset());

                        $(parentElement).animate(
                            {
                                scrollTop: topPoint.offset().top,
                                //scrollTop: $('input[id="' + firstError + '"]').offset().top - 100
                            },
                            500
                        );

                        $("#" + firstError).focus();

                        $("#errorbox").addClass("alert alert-danger");

                        var errorList = validator.errorList;

                        $.each(errorList, function (key, value) {
                            console.log(value.message);
                            var inputName = value.element.attributes.name.value;
                            //console.log(inputName);
                            $("#errorbox-container").append(
                                "*** " + value.message + "<br>"
                            );
                            //$("#errorbox-container").append('*** ' + inputName + ' ' + value.message + '<br>');
                        });

                        //Evidenzio il tab con gli errori
                        if (hasTab == "true") {
                            $(".nav-tabs a small.required").remove();
                            var validatePane = $(
                                ".tab-content.tab-validate .tab-pane:has(input.error)"
                            ).each(function () {
                                var id = $(this).attr("id");
                                $(".nav-tabs")
                                    .find('a[href^="#' + id + '"]')
                                    .append(
                                        ' <small class="required" style="color:#f00">***</small>'
                                    );
                            });
                        }

                        return false;
                    } else {
                        $("#errorbox").removeClass("alert alert-danger");
                        $("#errorbox-container").html("");
                    }
                });
            },
        });

        /*
        //Regole per hiddenRecaptcha
        hiddenRecaptcha: {
            required: function() {
                if (grecaptcha.getResponse() == '') {
                    return true;
                } else {
                    return false;
                }
            }
        }
        */

        return form.valid();
    }

    //Invio form
    sendForm(formId) {
        // console.clear();
        console.log("Adesso spedisco questa form: " + formId);

        var rotta = $("#" + formId).attr("data-route");
        var successFunction = $("#" + formId).attr("data-success");
        var dataRoute = $("#" + formId).attr("data-route"); //Spedisco la form in ajax o no. Default false

        console.log("SONO DENTRO SENDFORM");
        console.log(rotta);
        console.log(dataRoute);

        /*
        var pageSize = $('#' + formId).attr('data-pagesize');
        var pages = $('#' + formId).attr('data-pages');
        var currentPage = $('#' + formId).attr('data-currentpage');
        */
        var isAjax =
            dataRoute == "" || typeof dataRoute == "undefined" ? false : true;

        //console.log($('#' + formId).serializeArray());
        //console.log(isAjax);

        if (isAjax == true) {
            var formValues = $("#" + formId).serializeArray();

            /*
             *************************************************************************
             *
             * Fix per leggere i campi editor da chiamata ajax
             *
             *************************************************************************
             */

            /* console.log(tinymce.get('abstract').getContent()) */

            /* for (var i = 0; i < tinymce.editors.length; i++) {
                var editorId = tinymce.editors[i].id;
                console.log(editorId)
                var content = tinymce.editors[i].getContent();
            
                for (var index = 0; index < formValues.length; ++index) {
                    if (formValues[index].name == editorId) {
                        formValues[index].value = content;
                    }
                }
            }   */

            
            formValues.map((singleField) => {
                if(singleField.name != undefined){
                    var puntatore = singleField.name;
                    var elementClass = $("#" + formId + " [name='" + puntatore + "']").attr("class");

                    console.log(puntatore, "ELEMENT CLASS: " + elementClass);
                    
                    if(elementClass != undefined){
                        if (
                            elementClass.includes("text-editor") ||
                            elementClass.includes("text-editor-compact") ||
                            elementClass.includes("text-editor-mini")
                        ) {
                            singleField.value = tinymce.get(puntatore).getContent();
                            //console.log("Questo è il contenuto di TINYMCE a cui punto" + tinymce.get(puntatore).getContent());
                        }
                    }

                }
            });

            //console.log(formValues);

            var postParams = {
                type: "POST",
                uri: dataRoute,
                data: {
                    formValues: formValues, //$('#' + formId).serializeArray(),
                },
                onSuccess: successFunction, //'submittedForm',
                params: {
                    formid: formId,
                },
            };

            console.log("post params");
            console.log(postParams);

            uf.ajaxPost(postParams);
        } else {
            $("#" + formId)[0].submit();
        }
    }
}

export default formUtilities; //ES6
