import { data } from "jquery";

class lapeUtilitiesFunctions {


    test() {

        console.error('lape utilities: ci sono');
    }


    /*
    ******************************************************************************************************************************************************
    *
    * Ajax Post
    *
    ******************************************************************************************************************************************************
    */
    /*
    Parameters
    uf = utilitiesFunctions
    pf = postSuccessFunctions
    postParams = Parametri per la chiamata ajax
    ac = (opz) autocompleteFunctions
    te = (opz) textEditorFunctions
    */

    ajaxPostLape(postParams) {

        var showLoading = true;

        if (showLoading) {
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        }

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: postParams.type,
            url: postParams.uri,
            data: postParams.data,
            success: function (response) {

                //Controllo se devo aprire una modale o fare chiamata ajax standard
                if (postParams.ismodal) {

                    var modalParams = {
                        'modalId': response.modalId,
                        'modalTitle': response.modalTitle,
                        'params': postParams.params,
                    }

                    //Che tipo di modale devo aprire
                    if (postParams.modalTicket) {
                        lape.showModalTicket(response, modalParams);
                    }
                    else {
                        uf.showModal(response, modalParams);
                    }

                } else {
                    //Chiamo dinamicamente la funzione di successo

                    pf.executeSuccess(postParams.onSuccess, response);
                };

                if (showLoading) {
                    uf.loaderStop();
                };

            },
            error: function (e, textStatus, errorThrown) {
                //console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                //console.log('Errore...\n' + JSON.stringify(e));

                var err = JSON.parse(e.responseText);
                console.log(err);

                uf.loaderStop();

                //Chiudo il loader in caso di errore
                uf.loader('sk-cube-grid', '<h5>Attenzione, si è verificato un errore nella richiesta...</h5>' + err.message + '<br>in ' + err.file + ' riga ' + err.line);
                setTimeout(function () {
                    uf.loaderStop();
                }, 5000);
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    |
    | MODALI
    |
    |--------------------------------------------------------------------------
    */

    //Chiamo la funzione per recuperare il contenuto da inserire nella modale
    callModalLape(params) {

        var postParams = {
            'type': 'POST',
            'uri': params.rotta,
            'data': params.data,
            'onSuccess': params.onSuccess,
            'params': params.params,
            'ismodal': true,
            'modalTicket': params.params.modalTicket,

        };

        //console.log(postParams);

        lape.ajaxPostLape(postParams);

    }


    /*
    ******************************************************************************************************************************************************
    *
    * Documents TO DO WIP
    *
    ******************************************************************************************************************************************************
    */

    /*
         dataTableDocuments(className, uf = null, pf = null) {

            var rows = typeof ($('.' + className).attr('data-pageLength')) !== 'undefined' ? $('.' + className).attr('data-pageLength') : 10;
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
            $('.' + className).DataTable({

                'order': [
                    //Numero della colonna dell'ordinamento di default e direzione
                    $('.' + className).attr('data-orderBy'),
                    $('.' + className).attr('data-orderDirection')
                    //3, 'desc'
                ],
                scrollX: $('.' + className).attr('data-scrollX'),
                columnDefs: [

                    //Le colonne con questa classe non sono ordinabili
                    {
                        targets: 'no-sort',
                        orderable: false
                    },

                    //L'ordinamento per data non funziona al 100%. Usata la tecnica del campo nascosto con formato data YMd dove serve
                    //Le colonne con questa classe vengono formattate in dd/mm/yyyy
                    {
                        targets: 'daydate',
                        render: function (data) {
                            return moment.utc(data).format('DD/MM/YYYY');
                        }
                    },

                    //Le colonne con questa classe vengono formattate in dd-MM-YYYY H:i
                    {
                        targets: 'datetime',
                        render: function (data) {
                            return moment.utc(data).format('DD/MM/YYYY HH:mm');
                        }
                    },

                    //Le colonne con questa classe vengono nascoste
                    {
                        targets: 'hide',
                        visible: false,
                        searchable: true,
                    },
                ],
                orderClasses: false,
                pageLength: rows,
                //deferRender: true,
                //deferLoading: 57,
                processing: true,
                //serverSide: true, <-- Se attivato non funziona paginazione!
                /*
                serverMethod: 'POST',
                * /
                ajax: {
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: 'POST',
                    url: $('.' + className).attr('data-content-route'),
                    dataSrc: function (json) {
                        //Make your callback here.
                        console.log(json);
                        uf.loaderStop();
                        return json.data;
                    }
                },
                columns: [
                    { data: 'document' },
                    { data: 'type' },
                    { data: 'path' },
                    { data: 'version' },
                    { data: 'year' },
                    { data: 'updated_at' },
                    { data: 'families' },
                    { data: 'products' },
                    { data: 'visible' },
                    { data: 'selected' },
                    { data: 'preview' },
                ],

                //info: false,
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
                },

            });

        };
        */

    /*
     ******************************************************************************************************************************************************
     *
     * Select 2
     *
     ******************************************************************************************************************************************************
     */

    loadSuggestionS2(element) {

        var type = $(element).attr('data-id');

        //console.log($(element).attr("data-route"));

        $(element)
            .select2({
                minimumInputLength: 3,
                allowClear: true,
                placeholder: $(element).attr("data-placeholder"),
                theme: "bootstrap-5",

                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'search-city':
                                        obj = {
                                            text: item.name + ' [' + item.code + ']',
                                            id: item.id,
                                            type: type,
                                        }
                                        break;

                                    case 'search-user':
                                        obj = {
                                            text: /*item.id + '] ' +*/ item.surname + ' ' + item.name,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;
                                    case 'sample-recipient':
                                        obj = {
                                            text: item.surname + ' ' + item.name,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;

                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on("select2:open", function (e) {
                document.querySelector('.select2-search__field').focus();
            })

            .on('select2:select', function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val(cityId);
                        break;

                    case 'search-user':
                        /*
                        var userId = data.id;
                        $('#userid').val(userId);
                        */
                        break;
                    case 'sample-recipient':
                        /*
                        var userId = data.id;
                        $('#userid').val(userId);
                        */
                        break;

                }
            })
            .on('select2:clear', function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val('');

                        break;

                    case 'search-user':

                        var userId = data.id;
                        $('#userid').val('');

                        break;

                    case 'sample-recipient':

                        var userId = data.id;
                        $('#recipient').val('');

                        break;


                }
            })
    }


    /*
     ******************************************************************************************************************************************************
     *
     * Tickets
     *
     ******************************************************************************************************************************************************
     */

    //Mostro modale e aggiorno stato ticket
    showModalTicket(response, params) {

        //console.log(response);
        //console.log(params);

        var modalId = params.modalId;

        $('#' + modalId + ' .modal-body').html(response.view);
        $('#' + modalId + ' .modal-footer').html(response.footerView); //Eventale vista da includere nel footer
        //$('#' + modalId + ' .' + params.containerClass).html(response.view);
        $('#' + modalId).modal({
            backdrop: 'static' //Chiudo la modal solo cliccando su close
        });

        /*
        ***********************************************************
        *
        * AGGIORNO STATO TICKET
        *
        * **********************************************************
        */
        $("#ticketrow_" + response.ticketid).html(response.rowview);

        /*
        ***********************************************************
        *
        * PARAMETRI OPZIONALI PER CHIAMARE DEI PLUGIN
        *
        * **********************************************************
        */

        //Tinymce:  Nome della classe a cui applicare il tinymce
        if (params.params.editorId !== '') {
            //te.removeAll();
            //te.removeEditor(params.params.editorId);
            te.loadTinyMCEEditor();
        }

        //Datepicker: Nome della classe a cui applicare il datepicker
        if (params.params.datepickerClass !== '') {
            uf.myDatePicker(params.params.datepickerClass);
        }

        //Datatble: Nome della classe della tabella a cui applicare il datatable
        if (params.params.datatableClass !== '') {
            uf.dataTable(params.params.datatableClass);
        }

        //Select2: Nome della classe a cui applicare la select2
        if (params.params.select2Class !== '') {
            uf.loadCitiesS2(params.params.select2Class);
        }

        //Multiselect: Nome della classe a cui applicare il multiselect
        if (params.params.multiselectClass !== '') {
            uf.jqMultiselect(params.params.multiselectClass);
        }
        //S2 per la ricerca di un prodotto
        if (params.params.s2SearchProducts !== '') {
            uf.s2SearchProducts('.s2autocompleteProduct');
        }


    }


    /*
     ******************************************************************************************************************************************************
     *
     * Datatable rubrica potenziata
     *
     ******************************************************************************************************************************************************
     */


    dataTableRubricaAjax(className) {

        var rows = typeof ($('.' + className).attr('data-pageLength')) !== 'undefined' ? $('.' + className).attr('data-pageLength') : 10;
        uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        $('.' + className).DataTable({

            'order': [
                //Numero della colonna dell'ordinamento di default e direzione
                $('.' + className).attr('data-orderBy'),
                $('.' + className).attr('data-orderDirection')
                //3, 'desc'
            ],
            scrollX: $('.' + className).attr('data-scrollX'),
            columnDefs: [

                //Le colonne con questa classe non sono ordinabili
                {
                    targets: 'no-sort',
                    orderable: false
                },

                //L'ordinamento per data non funziona al 100%. Usata la tecnica del campo nascosto con formato data YMd dove serve
                //Le colonne con questa classe vengono formattate in dd/mm/yyyy
                {
                    targets: 'daydate',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY');
                    }
                },

                //Le colonne con questa classe vengono formattate in dd-MM-YYYY H:i
                {
                    targets: 'datetime',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY HH:mm');
                    }
                },

                //Le colonne con questa classe vengono nascoste
                {
                    targets: 'hide',
                    visible: false,
                    searchable: true,
                },
            ],
            ordering: false,
            orderClasses: false,
            pageLength: rows,
            //deferRender: true,
            //deferLoading: 57,
            processing: true,
            serverSide: true, //<-- Se attivato non funziona paginazione!
            /*
            serverMethod: 'POST',
            */
            ajax: {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'POST',
                url: $('.' + className).attr('data-content-route'),
                dataSrc: function (json) {
                    //Make your callback here.
                    //console.log(json);
                    uf.loaderStop();
                    return json.data;
                }
            },
            columns: [
                { data: 'channels' },
                { data: 'business_name' },
                { data: 'vat' },
                { data: 'province' },
                { data: 'current_revenue' },
                { data: 'prev_revenue' },
                { data: 'classe_fatturato' },
                { data: 'classe_potenziale' },
                { data: 'visit_number' },
                { data: 'actions' },
            ],
            // Il contenuto viene caricato dal Controller "Lape/CompanyController@getCompanyData";

            //info: false,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
            },

        });

    };



    //Calcolo n° pacchi
    //Parameters
    //Lunghezza articolo (in mm)
    //Larghezza articolo (in mm)
    //totalThickness
    //N° pezzi per pacco
    ///Qta ordinata
    //priceMeasure: unità di misura dell'articolo
    //priceMeasureMago: unità di misura che viene da mago

    getPackageNumber(lenght, width, totalThickness, itemPerPackage, packagePerPallet, qta, priceMeasure, priceMeasureMago, packageVolume) {

        //mq = Lunghezza * largezza in mm / 1000 (per avere i metri)

        //console.log(priceMeasureMago + ' ' + qta);

        var data = null;

        if (itemPerPackage != '') {

            // if (priceMeasureMago.toUpperCase == 'M2') {
            //     var article_mq = Number((lenght * width) / (1000 * 1000));
            //     var package_mq = Number((article_mq * itemPerPackage));
            //     var how_many_package_mq = Math.ceil(qta / package_mq);
            //     var adjusted_quantity_mq = (how_many_package_mq * package_mq);
            //     var pallets_mq = (how_many_package_mq / packagePerPallet);


            //     var data = {
            //         article_mq: article_mq,
            //         package_mq: package_mq,
            //         how_many_package_mq: parseFloat(how_many_package_mq).toFixed(2).toLocaleString('it-IT'),
            //         adjusted_quantity_mq: parseFloat(adjusted_quantity_mq).toFixed(2).toLocaleString('it-IT'), //Qta arrotondata per avere un numero di pacchi intero
            //         pallets_mq: parseFloat(pallets_mq).toFixed(2).toLocaleString('it-IT'),


            //     }

            // }
            // else {

            //     //Devo convertire items per package in mq
            //     var article_mq = Number((lenght * width) / (1000 * 1000));
            //     var package_mq = Number((article_mq * itemPerPackage));
            //     //var package_mq = Number(( (article_mq * totalThickness) /1000));
            //     var how_many_package_mq = Math.ceil(qta / package_mq);
            //     var adjusted_quantity_mq = (how_many_package_mq * package_mq);
            //     var pallets_mq = (how_many_package_mq / packagePerPallet);

            //     //MC
            //     var article_mc = (lenght * width * totalThickness) / (1000 * 1000 * 1000);
            //     var package_mc = Number((article_mc * itemPerPackage));
            //     var how_many_package_mc = Math.ceil(qta / package_mc);
            //     var adjusted_quantity_mc = (how_many_package_mc * package_mc);
            //     var pallets_mc = (how_many_package_mc / packagePerPallet);



            //     //console.log(Number.isInteger(pallets_mq));

            //     var data = {
            //         article_mq: article_mq,
            //         package_mq: package_mq,
            //         how_many_package_mq: parseFloat(how_many_package_mq).toFixed(2).toLocaleString('it-IT'),
            //         adjusted_quantity_mq: parseFloat(adjusted_quantity_mq).toFixed(2).toLocaleString('it-IT'), //Qta arrotondata per avere un numero di pacchi intero
            //         pallets_mq: parseFloat(pallets_mq).toFixed(2).toLocaleString('it-IT'),

            //         //MC
            //         article_mc: article_mc,
            //         package_mc: package_mc,
            //         how_many_package_mc: parseFloat(how_many_package_mc).toFixed(2).toLocaleString('it-IT'),
            //         adjusted_quantity_mc: parseFloat(adjusted_quantity_mc).toFixed(2).toLocaleString('it-IT'), //Qta arrotondata per avere un numero di pacchi intero
            //         pallets_mc: parseFloat(pallets_mc).toFixed(2).toLocaleString('it-IT'),

            //         //depalletizingCost: depalletizingCost,

            //     }

            // }



            console.log('@@@@@@@@@@@@@@@@@@@@@ eval package');
            console.log({ 'lenght': lenght, 'width': width, 'totalThickness': totalThickness, 'itemPerPackage': itemPerPackage, 'packagePerPallet': packagePerPallet, 'qta': qta, 'priceMeasure': priceMeasure, 'priceMeasureMago': priceMeasureMago, 'packageVolume': packageVolume })


            $.ajax('/admin/ajax/offers/get_package_number', {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'POST',
                async: false,
                data: { 'lenght': lenght, 'width': width, 'totalThickness': totalThickness, 'itemPerPackage': itemPerPackage, 'packagePerPallet': packagePerPallet, 'qta': qta, 'priceMeasure': priceMeasure, 'priceMeasureMago': priceMeasureMago, 'packageVolume': packageVolume },
                success: function (ret, status, xhr) {
                    console.log(ret)

                    let depalletizingCost = Number.isInteger(ret.pallets_mq) ? false : true;
                    console.log('priceMeasure')
                    console.log(priceMeasure)
                    if (priceMeasure.toUpperCase() == 'M3' || priceMeasure.toUpperCase() == 'MC') {
                        console.log('SONO DENTRO M3')
                        depalletizingCost = Number.isInteger(ret.pallets_mc) ? false : true;
                    }
                    // alert(ret.how_many_package_mq, ret.pallets_mq, depalletizingCost);
                    console.log("\n\n\nᴅᴀᴛᴀ sᴇᴛDᴇᴘᴀʟʟᴇᴛɪᴢɪɴɢCᴏsᴛ 2")
                    console.log(ret.how_many_package_mq, ret.pallets_mq,ret.how_many_package_mc, ret.pallets_mc, depalletizingCost)
                    let how_many_package_um = ret.how_many_package_mq
                    let pallets_um = ret.pallets_mq
                    if (priceMeasure == 'M3' || priceMeasure == 'MC') {
                        how_many_package_um = ret.how_many_package_mc
                        pallets_um = ret.pallets_mc
                    }
                    lape.setDepalletizingCost(how_many_package_um, pallets_um, depalletizingCost);

                    return ret;
                },
                error: function (jqXhr, textStatus, errorMessage) {
                    console.error('Errore' + errorMessage);
                }
            });






            //console.log(data);

            //controllo se applicare costo spallettizazione

        }


        return data;

    }


    /*
    Funzione inversa che ricava la quantità dato il numero di pacchi
    - packages: numero di pacchi
    - packagePerMq: numero di pacchi/mq
    - packagePerPallet: numero di pacchi/pallet
    */
    getQtaFromPackages(packages, packagePerMq, packagePerMc, packagePerPallet, um) {

        var data = null;
        console.log('###############################');
        console.log(packages);
        console.log(packagePerMq);
        console.log(packagePerMc);
        console.log(packagePerPallet);
        console.log(um);

        if (packages != '') {

            if (um.toLowerCase() == 'mq' || um.toLowerCase() == 'm2') {

                var qta = Number(packages * packagePerMq);
                var pallets = (packages / packagePerPallet);
                console.log('QTA 1: '+qta);
                console.log('PALLETS 1: '+pallets);
            }
            else {
                var qta = Number(packages * packagePerMc);
                var pallets = (packages / packagePerPallet);
                console.log('QTA 2: '+qta);
                console.log('PALLETS 2: '+pallets);
            }

            //controllo se applicare costo spallettizazione
            var depalletizingCost = Number.isInteger(pallets) ? false : true;

            //console.log(Number.isInteger(pallets));
            /*
            var qta = Number(packages * packagePerMq);
            var pallets = (packages / packagePerPallet);
            */
            var data = {
                mq: parseFloat(qta).toFixed(2).toLocaleString(),
                pallets: parseFloat(pallets).toFixed(2).toLocaleString(),
                //depalletizingCost: depalletizingCost,

            }
            console.log("\n\n\nᴅᴀᴛᴀ sᴇᴛDᴇᴘᴀʟʟᴇᴛɪᴢɪɴɢCᴏsᴛ 1")
            console.log(data)
            console.log(packages, pallets, depalletizingCost)
            // alert(packages,pallets,depalletizingCost);
            lape.setDepalletizingCost(packages, pallets, depalletizingCost);


        }
        console.log('###############################');

        return data;

    }



    /*
    Calcola la congruenza di un prezzo in base agli sconti applicati
    - Scala sconto max applicabile
        - discountScale1: int
        - discountScale2: int
        - discountScale3: int

    - basePrice: prezzo pieno dell'articolo
    - discount1: int, valore dello sconto 1
    - discount2: int, valore dello sconto 2
    - discount3: int. valore dello sconto 3
    */
    getDisocuntPrice(basePrice, totalPrice, /*discountscale1, discountscale2, discountscale3,*/ discount1 = 0, discount2 = 0, discount3 = 0, max_sconto_extra=0) {


        var congruous = true;
        var discountedPrice = totalPrice;
        var minimumPrice = 0;

        //Scala max sconto applicabile
        var discountscale1 = $('#discountscale').attr('data-discount1-value');
        var discountscale2 = $('#discountscale').attr('data-discount2-value');
        var discountscale3 = $('#discountscale').attr('data-discount3-value');


        var quantityMc = $('#quantityMcLabel').attr('data-value');
        // var discountscale3 = $('#discountscale').attr('data-discount3-value');

        console.log({'basePrice': basePrice,'totalPrice': totalPrice,'discount1': discount1,'discount2': discount2,'discount3': discount3,'max_sconto_extra': max_sconto_extra,'discountedPrice': discountedPrice,'minimumPrice': minimumPrice,'discountscale1': discountscale1,'discountscale2': discountscale2,'discountscale3': discountscale3,'quantityMc': quantityMc});
        let values={
            'discountedPrice': 0,
            'congruous': true,
        };
        $.ajax('/admin/ajax/offers/evaluate_discount_price', {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: {'basePrice': basePrice,'totalPrice': totalPrice,'discount1': discount1,'discount2': discount2,'discount3': discount3,'max_sconto_extra': max_sconto_extra,'discountedPrice': discountedPrice,'minimumPrice': minimumPrice,'discountscale1': discountscale1,'discountscale2': discountscale2,'discountscale3': discountscale3,'quantityMc': quantityMc},
            success: function (data, status, xhr) {


                $('#articlePrice').html(parseFloat(data.basePrice).toFixed(2).toLocaleString());
                $('#prezzo_mc').html(parseFloat(data.prezzomc).toFixed(2).toLocaleString());
                $('#discountPrice').html(parseFloat(data.discountedPrice).toFixed(2).toLocaleString());
                $('#discountPrice').attr('data-price',parseFloat(data.discountedPrice).toFixed(2).toLocaleString());


                values = {
                    'discountedPrice': data.discountedPrice,
                    'congruous': data.congruous,
                };
                console.log('-----------------------');
                console.log(values);

                return values;
            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });






        return values;

    }

    setCongruousPrice(congruous) {
        console.log('setCongruousPrice');
        console.log(congruous);

        if (!congruous) {
            console.log('danger');
            $('#discountPrice').addClass('text-danger');
            $('#congruouspriceContainer').addClass('badge rounded-pill bg-danger').html($('#congruouspriceContainer').attr('data-message'));
        }
        else {
            console.log('not danger');
            $('#discountPrice').removeClass('text-danger');
            $('#congruouspriceContainer').removeClass('badge rounded-pill bg-danger').html('');
        }
        var prezzo_congruo=0;
        if(congruous){
            prezzo_congruo=1;
        }
        console.log('PREZZO CONGRUO:'+prezzo_congruo);
        $('#congruouspriceContainer').attr('data-value', prezzo_congruo); //.html(congruous);

    }


    //Stampo numero di pacchi, pallet e alert costo spallettizazione
    setDepalletizingCost(packages, pallets, depalletizingCost) {
        //Funzione unificata per stampo numero pallet

        //console.log('.......' + depalletizingCost);
        console.log('setDepalletizingCost',packages,pallets,depalletizingCost);
        //Aggiorno n° pacchi
        $('#packageNumber').val(packages);


        //Stampo n° pallet
        $('#palletNumber').html(pallets.toFixed(2).toLocaleString('it-IT') + ' pallets');


        if (depalletizingCost) {
            //Assegno costi
            $('#palletNumber').removeClass('text-success').addClass('text-danger');
            $('#palletNumberIcon').removeClass('text-success fa-check-circle').addClass('text-danger fa-exclamation-triangle');
            $('#depallettizingText').html($('#depallettizingText').attr('data-message'));
        }
        else {
            //Nessun costo
            $('#palletNumber').removeClass('text-danger').addClass('text-success');
            $('#palletNumberIcon').removeClass('text-danger fa-exclamation-triangle').addClass('text-success fa-check-circle');
            $('#depallettizingText').html('');

        }



    }

    //Converte le quantità da mq a mc e viceversa

    /*
    SelectedRow: riga dello spessore dell'articolo selezionata
    selectedUm: unutà di misura selezionata
    */

    convertUm(rowId, selectedUm, base_uom, colli_uom_misbase) {

        //console.log(selectedUm);

        //Dettagli articolo per calcolo prezzo e pallet
        var lenght = $('#' + rowId).attr('data-article-lenght');
        var width = $('#' + rowId).attr('data-article-width');
        var thickness = $('#' + rowId).attr('data-article-thickness');
        var priceMeasure = $('#' + rowId).attr('data-article-um');
        var priceMeasureMago = $('#' + rowId).attr('data-article-magoum');
        var itemsPackage = $('#' + rowId).attr('data-items-package');
        var packagesPerPallet = $('#' + rowId).attr('data-package-pallet');
        var packageVolume = $('#' + rowId).attr('data-package-volume');



        //var qta = $('#quantity').val();
        var qta = $('#quantity').data('digited_quantity');


        //Leggo il n° dei pacchi e converto il formato
        var packs = $('#packageNumber').val();

        // if (selectedUm.toUpperCase() == 'MQ') {
        //     var qtaProduct = Number((lenght * width) / (1000 * 1000));
        // }
        // else {
        //     var qtaProduct = (lenght * width * thickness) / (1000 * 1000 * 1000);
        // }

        // var qtaPacks = qtaProduct * itemsPackage;
        // var newQta = packs * qtaPacks

        // $('#quantity').val(data.toFixed(2).toLocaleString('it-IT'));

console.log('QTA '+qta);
        $.ajax('/admin/ajax/offers/convert_um', {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: {'qtaDesiderata':qta,'selectedUm':selectedUm,'width':width,'lenght':lenght,'thickness':thickness,'itemsPackage':itemsPackage,'packs':packs,'packageVolume':packageVolume,'base_uom':base_uom,'colli_uom_misbase':colli_uom_misbase},
            success: function (data, status, xhr) {
                // console.clear();
                // console.warn(data);

                console.warn(data.newQta);
                console.warn(data.newQtaPacks);
                $('#quantity').val(parseFloat(data.newQta).toFixed(2).toLocaleString('it-IT'));
                $('#packageNumber').val(data.newQtaPacks);

                var um = $('#quantityLabel').attr('data-value');
                var rowId = $('#selectedRow').attr('data-value');
                lape.evaluateMcQnt(rowId, um);

            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });




    }





    evaluateMcQnt(rowId, selectedUm) {

        var rowId = $('#selectedRow').attr('data-value');
        console.log(rowId);
        //Dettagli articolo per calcolo prezzo e pallet

        var packageVolume = $('#' + rowId).attr('data-package-volume');

        //var qta = $('#quantity').val();
        var qta = $('#quantity').data('digited_quantity');


        //Leggo il n° dei pacchi e converto il formato
        var packs = $('#packageNumber').val();

        console.log('packs 1:' + packs)
        $.ajax('/admin/ajax/offers/evaluate_mc_packs', {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: {'packs': packs,'packageVolume': packageVolume},
            success: function (data, status, xhr) {

                console.log('NEW QTA MC ONLOAD: '+data);
                data=parseFloat(data);
                if(!isFinite(data)){
                    data=0;
                }
                $('#quantityMcLabel').html(data.toFixed(2)+' MC');
                $('#quantityMcLabel').attr('data-value',data);

                // var um = $('#quantityLabel').attr('data-value');
                // if (um.toUpperCase() == 'M3' || um.toUpperCase() == 'MC') {
                //     $('#quantity').val(data);
                // }


            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });

    }




    /*setCourtesyPrice(){

        // Calcolo il costo al MQ del prodotto COMPRENSIVO del suo trasporto:
        // 1) prendo il costo al MQ comprensivo degli sconti: prezzoMQ
        var qnt_mq = $('#quantity').val();                              // Quantità totale in Metri Quadri
        var prezzoMQ = $('#articlePrice').text();                       // Prezzo finito con sconti al Metro Quadro

        // 2) prendo il costo di trasporto totale e lo divido per i Metri Quadri:
        //console.log('Costo trasporto totale: ' + data);
        //console.log('Metri Quadri totali: ' + qnt_mq);
        var costoTraspTot = $('#shipmentCostText').attr('data-value');
        var sped_costoMQ = parseFloat(costoTraspTot)/parseFloat(qnt_mq);

        // 3) sommo al costo del materiale al MQ anche il costo di trasporto al MQ:
        console.log('costo trasporto al MQ: ' + sped_costoMQ);
        console.log('prezzo finito al MQ: ' + prezzoMQ);
        var costoTOT_mq = parseFloat(sped_costoMQ)+parseFloat(prezzoMQ);

        $('#costoConSpedizione').html("<b>"+costoTOT_mq.toFixed(2) + " €</b>");
    }*/




    evaluateMcQntOnLoad() {


        var rowId = $('#selectedRow').attr('data-value');
        console.log(rowId);
        //Dettagli articolo per calcolo prezzo e pallet

        var packageVolume = $('#' + rowId).attr('data-package-volume');

        //var qta = $('#quantity').val();
        var qta = $('#quantity').data('digited_quantity');


        //Leggo il n° dei pacchi e converto il formato
        var packs = $('#packageNumber').val();

        console.log('packs 1:' + packs)
        $.ajax('/admin/ajax/offers/evaluate_mc_packs', {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: {'packs': packs,'packageVolume': packageVolume},
            success: function (data, status, xhr) {
                console.log('NEW QTA MC ONLOAD: '+data);
                data=parseFloat(data);
                if(!isFinite(data)){
                    data=0;
                }
                $('#quantityMcLabel').html(data.toFixed(2)+' MC');
                $('#quantityMcLabel').attr('data-value',data);



            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });

    }

    printShipmentCostOffer(shipmentCostRoute){
        var offerdetailId = $('#offerid').attr('data-offerdetail-id'); //Id dell'eventuale articolo salvato se sto modificando un'offerta
        var offerId = $('#offerid').attr('data-value');
        var qnt_mc = $('#quantityMcLabel').attr('data-value');          // Quantità totale in Metri Cubi
        var prezzoMQ = $('#articlePrice').text().replace(',','.');      // Prezzo finito con sconti al Metro Quadro
        var qnt_mq = $('#quantity').val()
        var um = $('#quantityLabel').attr('data-value');
        console.log('SHIPMENT COST ROUTE');
        console.log('offerId: '+offerId);
        console.log('qnt_mc: '+qnt_mc);
        console.log('qnt_mq: '+qnt_mq);
        console.log(shipmentCostRoute);
        $.ajax(shipmentCostRoute, {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: { 'prezzoMQ': prezzoMQ, 'qnt_mc': qnt_mc, 'qnt_mq': qnt_mq, 'offerdetailId': offerdetailId, 'offerId': offerId, 'um': um },
            success: function (data, status, xhr) {

                console.log('_________PRINT SHIPMENT COST________');
                console.log(data);
                console.log('_________PRINT SHIPMENT COST________');

                if(data.cost_found && isFinite(data.totale_costo) ){
                    $('#shipmentCostText').html('<span class="fw-bold">' + data.totale_costo + " €</span>");
                    $('#shipmentCostText').attr('data-value', data.totale_costo);
                }else{

                    $('#shipmentCostText').html('<span class="fw-bold">0 €<br>Nessun costo di spedizione è stato trovato.</span>');
                    $('#shipmentCostText').attr('data-value', 0);

                }

                if(isFinite(data.totale_costo) ){
                    $('#costoConSpedizione').html("<b>"+data.costoTOT_mq.toFixed(2).toLocaleString('it-IT') + " €</b>");
                }else{
                    $('#costoConSpedizione').html("<b>0 €</b>");
                }
            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });
    }








    evaluateCommissions() {
        var rowId = $('#selectedRow').attr('data-value');
        var offerdetailId = $('#offerid').attr('data-offerdetail-id');
        var family = $('#'+rowId).attr('data-family-id');
        var offerId = $('#offerid').attr('data-value');
        var price = $('#discountPrice').attr('data-price');

        var discount_1 = $('#discount_1:checked').data('value')|0;
        var discount_2 = $('#discount_2:checked').data('value')|0;
        var discount_3 = $('#discount_3').val();
        var discount_id = $('#discount_id').val();

        console.log({"family": family,"offerId": offerId,"detail": offerdetailId,"price": price,"discount_1": discount_1,"discount_2": discount_2,"discount_3": discount_3});
        $.ajax('/admin/ajax/lape/commissions/evaluate/modal', {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: 'POST',
            async: false,
            data: { "family": family, "offerId": offerId, "detail": offerdetailId, "price": price, "discount_1": discount_1, "discount_2": discount_2, "discount_3": discount_3, "discount_id": discount_id },
            success: function (data, status, xhr) {

                $('.commissions_modal #commissionPercent').html('(' + data.percentage + '%)');
                $('.commissions_modal #commissionValue').html(parseFloat(data.commissions).toFixed(2).toLocaleString() + ' €');

            },
            error: function (jqXhr, textStatus, errorMessage) {
                console.error('Errore' + errorMessage);
            }
        });






        return true;

    }








}

export default lapeUtilitiesFunctions // ES6
