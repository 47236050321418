class texteditorFunctions {

    test() {
        alert('text editor: ci sono');
    }




    /*
     ******************************************************************************************************************************************************
     *
     * TinyMCE
     *
     ******************************************************************************************************************************************************
     */
    mceInit() {

        /* Configurazione editor con classe ".text-editor" */
        var editor_config = {
            height: '150px',
            selector: '.text-editor',
            width: '100%',
            language: 'it',
            plugins: [
                'advlist autolink lists link image charmap print preview anchor textcolor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table contextmenu paste help wordcount'
            ],
            toolbar: 'insert | undo redo | formatselect | bold italic backcolor table | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat | customSeparatorButton | code help',
            branding: false,
            menubar: false,
            extended_valid_elements: 'i[class]',

            /*IMAGE PLUGIN */
            image_advtab: true,
            style_formats: [{
                    title: 'Image Left',
                    selector: 'img',
                    styles: {
                        'float': 'left',
                        'margin': '0 10px 0 10px'
                    }
                },
                {
                    title: 'Image Right',
                    selector: 'img',
                    styles: {
                        'float': 'right',
                        'margin': '0 10px 0 10px'
                    }
                }
            ],
            image_class_list: [{
                title: 'Responsive',
                value: 'img-fluid'
            }, ],

            // without images_upload_url set, Upload tab won't show up
            //images_upload_url: $('.text-editor').attr('data-upload-route'),
            //images_upload_base_path: '/some/basepath',
            //images_upload_credentials: true

            /* enable title field in the Image dialog*/
            image_title: true,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: true,

            file_picker_types: 'image',

            /*
             *****************************************
             * Laravel filemanager
             *****************************************
             */
            path_absolute: "",
            file_browser_callback: function (field_name, url, type, win) {
                var x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
                var y = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

                //var cmsURL = editor_config.path_absolute + 'laravel-filemanager?field_name=' + field_name;
                var cmsURL = editor_config.path_absolute + $('.tinyuploadmanager').attr('data-upload-url') + '?field_name=' + field_name;
                if (type == 'image') {
                    cmsURL = cmsURL + "&type=Images";
                } else {
                    cmsURL = cmsURL + "&type=Files";
                }

                tinyMCE.activeEditor.windowManager.open({
                    file: cmsURL,
                    title: 'Filemanager',
                    width: x * 0.8,
                    height: y * 0.8,
                    resizable: "yes",
                    close_previous: "no"
                });
            },

            //alternative config on a mobile device
            // mobile: {
            //     theme: 'mobile',
            //     //plugins: ['autosave', 'lists', 'autolink'],
            //     plugins: [
            //         'advlist autolink lists link image imagetools charmap print preview anchor textcolor',
            //         'searchreplace visualblocks code fullscreen',
            //         'insertdatetime media table contextmenu paste help wordcount'
            //     ],
            //     //toolbar: ['undo', 'bold', 'italic', 'styleselect']
            //     toolbar: ['insert | undo redo | formatselect | bold italic backcolor  | link | image | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat | code help'],

            // }


        };

        /* Configurazione editor con classe ".text-editor-compact"
        Utilizzata per la modifica delle descrizioni dei prodotti */
        var abstract_config = {
            height: '150px',
            selector: '.text-editor-compact',   // Questo è il puntatore alla TEXTAREA usata per la risposta dei Ticket
            width: '100%',
            language: 'it',
            plugins: [
                'advlist autolink lists link charmap print preview anchor textcolor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime table contextmenu paste help wordcount'
            ],
            toolbar: 'code insert | undo redo | formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat | help',
            branding: false,
            menubar: false,

            // without images_upload_url set, Upload tab won't show up
            //images_upload_url: $('.text-editor').attr('data-upload-route'),
            //images_upload_base_path: '/some/basepath',
            //images_upload_credentials: true

            //alternative config on a mobile device
            // mobile: {
            //     theme: 'mobile',
            //     //plugins: ['autosave', 'lists', 'autolink'],
            //     plugins: [
            //         'advlist autolink lists link imagetools charmap print preview anchor textcolor',
            //         'searchreplace visualblocks code fullscreen',
            //         'insertdatetime table contextmenu paste help wordcount'
            //     ],
            //     //toolbar: ['undo', 'bold', 'italic', 'styleselect']
            //     toolbar: ['code insert | undo redo | formatselect | bold italic backcolor  | link | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat | help code'],

            // }
        };


        /* Configurazione editor con id "message"
        Utilizzato per il commento ai ticket */
        var simple_config = {
            selector: 'textarea#message',
            height: 350,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
        }



        //Minimal
        /* Text editor utilizzato per ragioni archiviazione offerta in resources/views/admin/lape/offers/modals/reason.blade.php
        al momento l'editor non viene utilizzato  */
       /*  var minimal_config = {
            height: '40px',
            selector: '.text-editor-mini',
            width: '100%',
            language: 'it',
            plugins: ['autolink link charmap anchor textcolor code contextmenu paste help'],
            toolbar: 'code | undo redo | formatselect | bold italic backcolor | link | removeformat',
            branding: false,
            menubar: false,

            //alternative config on a mobile device
            // mobile: {
            //     theme: 'mobile',
            //     plugins: ['autolink link charmap anchor textcolor code contextmenu paste help'],
            //     toolbar: 'code | undo redo | formatselect | bold italic backcolor | link | removeformat',
            // }


        }; */


        tinymce.init(editor_config);
        tinymce.init(abstract_config);
        tinymce.init(simple_config);
        //tinymce.init(minimal_config);



        console.log('te:', te);
        console.log('TinyMCE disponibile:', typeof tinymce !== 'undefined');


    }

    //For ajax generated editor
    loadTinyMCEEditor() {

        this.mceInit();

    }


    //Rimuovo istanza se esiste già
    removeEditor(editorId) {

        for (var i = tinymce.Editor.length - 1; i > -1; i--) {
            var ed_id = tinymce.Editor[i].id;
            alert(ed_id)
            if(ed_id == editorId) {
               tinyMCE.execCommand("mceRemoveEditor", true, ed_id);
            }
        }
    }

     //Rimuovo itutte le istanze
    removeAll() {

        tinymce.remove();
    }


}


export default texteditorFunctions // ES6
