import { each } from "jquery";

class postSuccessFunctions {
    //Chiamata dinamica della funzione di successo sull'ajaxpost
    executeSuccess(successFunction, response) {
        // console.log('+++++++++++++++++++');
        // console.log(successFunction);
        // console.log(response);
        // console.log('+++++++++++++++++++');
        if (successFunction !== "") {
            this[successFunction](response);
        }
    }

    /*
     *************************************************************************
     * FUNCTIONS
     *************************************************************************
     */

    //
    updateAdminMenuSection(response) {
        $("#caret_container" + response.sectionId).html(response.view);
    }

    //Users
    updateUsersList(response) {
        $("#users_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Templates
    updateTemplatesList(response) {
        $("#templates_container").html(response.view);
    }

    //Positions
    updatePositionsList(response) {
        $("#positions_container").html(response.view);
    }

    //Blocks
    updateBlocksList(response) {
        $("#blocks_container").html(response.view);
    }

    //Blocks - Carico record nella select di creazione/modifica blocco
    fillModelContent(response) {
        $("#records").html(response.view);
    }

    //Menus
    updateMenusList(response) {
        $("#menus_container").html(response.view);
    }

    //Menusections
    updateMenusectionsList(response) {
        $("#menusections_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Menuitems
    updateMenuitemsList(response) {
        $("#menuitems_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Pages
    updatePagesList(response) {
        $("#pages_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Categories
    updateCategoriesList(response) {
        $("#categories_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Tags
    updateTagsList(response) {
        $("#tags_container").html(response.view);
    }

    //Roles
    updateRolesList(response) {
        $("#roles_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Permissions
    updatePermissionsList(response) {
        $("#permissions_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    /*
     *************************************************************************
     * CONTACTS FORM
     *************************************************************************
     */
    updateContactsForm(response) {
        //console.log(response);
        $(".form-response").removeClass("collapse").html(response.responseText);
        $(".ajx-contactform")[0].reset();
        $("#errorbox-container").html("");
        $("#errorbox").removeClass("alert alert-danger");
    }

    updateProfessionList(response) {
        //console.log(response.professions);
        $("#profession_id").html(response.view);
    }

    /*
     *************************************************************************
     * MAILLING LIST
     *************************************************************************
     */
    //Mailinglist
    updateSubscribersList(response) {
        $("#subscribers_container").html(response.view);
    }

    updateMailingGroupsList(response) {
        $("#mlgroups_container").html(response.view);
    }

    updateMailinglistList(response) {
        $("#mailinglists_container").html(response.view);
    }

    //Mailinglist
    updateSubscribersList(response) {
        $("#subscribers_container").html(response.view);
    }

    //Iscrizione mailing list
    updateNewsletterForm(response) {
        //console.log(response);
        $(".newsletter-response")
            .removeClass("collapse")
            .addClass("alert-" + response.class)
            .html(response.responseText);

        if (response.class == "success") {
            $("#" + response.formid)[0].reset();
        }
    }

    //Cancellazione da mailing list
    updateUnsubscribeForm(response) {
        //console.log(response);
        $(".form-response")
            .removeClass("collapse")
            .addClass("alert-" + response.class)
            .html(response.responseText);

        if (response.class == "success") {
            $("#" + response.formid)[0].reset();
        }
    }

    /*
     ******************************************************************************************************************************************************
     * MEDIA
     ******************************************************************************************************************************************************
     */

    //Mostro i file della cartella
    updateImagesGrid(response) {
        $("#" + response.container).html(response.view);
        $("#selected_files").val(response.selectedImages);
        loadDropzone("dropzone_images");
    }

    //Gallery - Carico record nella select di creazione/modifica gallery
    getRelatedRecords(response) {
        $("#record_id").html(response.view);
    }

    //Gallery - Ricarico elenco files dopo aggiunta da modale
    updateTableItemsContainer(response) {
        //console.log(response.images);
        $("#table_items_container").html(response.view);
    }

    /*
     ******************************************************************************************************************************************************
     * UPLOADER
     ******************************************************************************************************************************************************
     */
    /*
     updateUploadList(response) {
         console.log(response.urls);
         $('.' + response.divtoreload).html(response.view);
     }
     */

    updateCoverContainer(response) {
        $("." + response.divtoreload + " .img").html(response.replaceHtml);
        $("#" + response.inputId).val("");
    }

    /*
     ******************************************************************************************************************************************************
     * DATATABLE
     ******************************************************************************************************************************************************
     */

    datatableUpdateSort(response) {
        //console.log(response);
    }

    /*
     ******************************************************************************************************************************************************
     *
     *
     * LAPE
     *
     *
     ******************************************************************************************************************************************************
     */

    //Filtri applicazioni su pagina prodotti
    updateApplicationTypeList(response) {
        $("#applications-list").html(response.view);
        $("#products-container").html(response.view_products);
    }

    //Sub Filtri applicazioni su pagina prodotti
    updateApplicationList(response) {
        //$('#applications-list').html(response.view);
        $("#products-container").html(response.view);
    }

    //Filtro documentazione in base al tipo di isolamento
    updateDocumentationList(response) {
        //console.log(response.insulationtypeId);
        $("#technical_documents_container").html("response.view");
    }

    /*
     ******************************************************************************************************************************************************
     * LAPE ADMIN
     ******************************************************************************************************************************************************
     */

    /*
     *********************************************************************
     *
     * COMPANIES / OFFICES
     *
     *********************************************************************
     */
    storeAndCloseCompany(response) {
        $("#company-msg-container").html(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            type: "POST",
            uri: response.officeRoute,
            data: {
                companyId: response.companyId,
                // 'new_company': true,
            },
            onSuccess: "showOfficesView",
            params: "",
        };

        //console.log(postParams);
        uf.ajaxPost(postParams);

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");
            // console.log('*********************');
            // console.log(response);

            // console.log('MODALID: '+modalId);
            // console.log('*********************');
            //Chiudo la modale se non c'è un messaggio di avviso
            // if (response.error == false) {
            if (response.msgView != "false") {
                $("#" + modalId).modal("toggle");
                $(".modal-backdrop").remove();
                $(document.body).removeClass("modal-open");
            }
        }
    }

    storeAndCloseOffice(response) {
        $("#office-msg-container").html(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            type: "POST",
            uri: response.officeRoute,
            data: {
                companyId: response.companyId,
            },
            onSuccess: "showOfficesView",
            params: "",
        };

        //console.log(postParams);
        uf.ajaxPost(postParams);

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    storeAndCloseReferent(response) {
        console.warn(response);
        $("#referent-msg-container").append(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            type: "POST",
            uri: response.referentRoute,
            data: {
                companyId: response.companyId,
                officeId: response.officeId,
            },
            onSuccess: "showReferentsView",
            params: "",
        };

        //console.log(postParams);
        uf.ajaxPost(postParams);

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    /*
     *********************************************************************
     *
     * FAMILIES / PRODUCTS / ARTICLES / DOCUMENTS
     *
     *********************************************************************
     */

    updateFamiliesList(response) {
        $("#families_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Ricarico lista allegati famiglia
    updateAttachmentList(response) {
        $("#documents_container").html(response.view);
    }

    updateProductsList(response) {
        $("#products_container").html(response.view);
        uf.dataTable("table-paginate");

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    //Disponibilità prodotti
    updateProductAvailabilitiesList(response) {
        console.log(response.view);
        $("#availabiltiies_list").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateArticlesList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
        uf.loadTippy();
    }

    //Carico lista prodotti su cambio famiglia
    articlesUpdateProductsList(response) {
        //console.log(response.familyId);
        var productDisabled = response.familyId > 0 ? false : true;
        var container = response.container;

        $("#" + container).prop("disabled", productDisabled);

        $("#" + container).html(response.view);
        $("#table_container").html("");

        uf.loadTippy();
    }

    //Mostro esito edit prodotto in modale
    showEditProductResult(response) {
        //console.clear();
        //console.log(response.fields);
        $("#table_container").html(response.listView);

        $("#" + response.modalId + " .modal-body").html(response.view);
        $("#" + response.modalId + " .modal-footer").html(response.footerView); //Eventale vista da includere nel footer
        uf.jqMultiselect("multiselect");
        uf.dataTable("table-paginate");
    }

    //Mostro esito edit articolo in modale
    showEditArticleResult(response) {
        //console.clear();
        //console.log(response);
        $("#table_container").html(response.listView);

        $("#" + response.modalId + " .modal-body").html(response.view);
        $("#" + response.modalId + " .modal-footer").html(response.footerView); //Eventale vista da includere nel footer
        uf.dataTable("table-paginate");
    }

    //Aggiorno elenco documenti
    updateDocumentsList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");

        //Tippyjs Tooltip
        tippy(".tippy", {
            allowHTML: true,
            arrow: true,
            theme: "light",
            maxWidth: 500,
        });
    }

    //Aggiorno riga tabella documenti
    updateDocumentsRow(response) {
        $("#row_" + response.documentId).html(response.view);

        //Tippyjs Tooltip
        tippy(".tippy", {
            allowHTML: true,
            arrow: true,
            theme: "light",
            maxWidth: 500,
        });
    }

    //aggiorno riga tabella prodotti
    productsUpdateProductRow(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        //var productDisabled = response.familyId > 0 ? false : true;

        $("#" + response.productId).html(response.view);
        //uf.dataTable("table-paginate");
    }

    //Carico lista articoli su cambio famiglia, eliminazione multipla
    articlesUpdateArticlesList(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        $("#destroyArticles").removeClass("d-none");
        var productDisabled = response.familyId > 0 ? false : true;

        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
        uf.loadTippy();
    }

    //aggiorno riga tabella articoli
    articlesUpdateArticleRow(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        //var productDisabled = response.familyId > 0 ? false : true;

        $("#" + response.articleId).html(response.view);
        //uf.dataTable("table-paginate");
    }

    updateApplicationtypesList(response) {
        $("#applicationtypes_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateApplicationsList(response) {
        $("#applications_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateCertificationsList(response) {
        $("#certifications_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatePropertygroupsList(response) {
        $("#propertygroups_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatedopgroupsList(response) {
        $("#dopgroups_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatePropertiesList(response) {
        $("#properties_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateTechnicalsheetsList(response) {
        $("#table_content").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateStandardsList(response) {
        $("#standards_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateDocumentpropertiesList(response) {
        //console.log(response.product);
        $("#table_content").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateTechnicalPropertiesList(response) {
        $("#technicalsheets_list_container").html(response.view);
        $("#propertyFilter").val(null);
        $("#propertyFilter").hideseek({
            attribute: "data-search",
        });
    }

    //Mostro form modifica proprietà nella gestione delle schede tecniche
    updateContainerItems(response) {
        $("#containerItems").html(response.view);

        //Select2 per le select dentro la form di modifica delle proprietà delle st
        $(".select2-item").select2({
            theme: "bootstrap-5",
        });
    }

    //Ricarico pagina edit dopo eliminazione document-property
    updateDocumentPropertiesContainer(response) {
        $("#documentproperties_list_container").html(response.view);

        //Select2 per le select dentro la form di modifica delle proprietà delle st
        //Select2 per la selezione di una proprietà della scheda tecnica
        $(".select2-properties")
            .select2({
                theme: "bootstrap-5",
                templateResult: formatOption,
            })
            .on("select2:select", function (e) {
                var data = e.params.data;
                var rotta = $(this).attr("data-route");
                var productId = $(this).attr("data-product-id");

                if (data.id != "") {
                    var postParams = {
                        type: "POST",
                        uri: rotta,
                        data: {
                            propertyId: data.id,
                            productId: productId,
                        },
                        onSuccess: "updateContainerItems",
                        params: "",
                    };

                    //console.log(postParams);
                    uf.ajaxPost(postParams);
                }
            });

        function formatOption(listItem) {
            var saved = $(listItem.element).data("saved");
            var bg = saved ? "bg-warning" : "";

            if (!listItem.id) {
                return listItem.text;
            }

            var $item = $(
                '<div class="py-1 ' + bg + '">' + listItem.text + "</div>"
            );
            return $item;
        }
    }

    //Update sort in modale
    updateTechnicalsheetsModalList(response) {
        $(".modal-body").html(response.view);
        uf.dataTable("table-paginate-modal");
    }

    //Update sort in modale
    updateDocumentpropertiesModalList(response) {
        $(".modal-body").html(response.view);
        uf.dataTable("table-paginate-modal");
    }

    //Opend technicalsheet duplicate modal
    openDuplicateModal(response) {
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId + " .modal-body").html(response.view);
            $("#" + modalId).modal({
                backdrop: "static", //Chiudo la modal solo cliccando su close
            });

            $(".duplicate-item")
                .select2({
                    theme: "bootstrap-5",
                    dropdownParent: $("#tsModal"),
                })
                .on("select2:select", function (e) {
                    var data = e.params.data;

                    var confirmParams = {
                        title: $(".duplicate-item").attr("data-confirm-title"),
                        message: $(".duplicate-item").attr(
                            "data-confirm-message"
                        ),
                        btnConfirm:
                            $(".duplicate-item").attr("data-btn-confirm"),
                        btnDismiss:
                            $(".duplicate-item").attr("data-btn-dismiss"),
                    };

                    //Rotta di conferma sulla selezione del prodotto
                    var confirmRoute = $(".duplicate-item").attr("data-route");

                    //Id del prodotto da duplicare
                    var parentId = $(".duplicate-item").attr("data-parent-id");

                    var postParams = {
                        type: "POST",
                        uri: confirmRoute,
                        data: {
                            parentId: parentId,
                            productId: data.id, //Nuovo prodotto a cui associare la scheda
                            modalId: modalId,
                        },
                        onSuccess: "closeAndUpdateDocumentpropertiesList",
                        params: "",
                    };

                    //console.log(postParams);

                    uf.confirmBox(confirmParams, postParams);
                });
        }
    }

    closeAndUpdateDocumentpropertiesList(response) {
        $("#archivelist").html(response.view);
        //uf.dataTable('table-paginate');

        //console.log(response.modelPlural);
        //console.log(response.modelSingular);
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    //Genero revisione
    /*
    updateArticlesList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
    }
    */

    //Chiudo modale gestione proprietà dopo salvataggio
    closeProductPropertyModal(response) {
        $("#product_properties_list").html(response.view);
        $("#documentproperties_list_container").html(response.selectView);

        function formatOption(listItem) {
            var saved = $(listItem.element).data("saved");
            var bg = saved ? "bg-warning" : "";

            if (!listItem.id) {
                return listItem.text;
            }

            var $item = $(
                '<div class="py-1 ' + bg + '">' + listItem.text + "</div>"
            );
            return $item;
        }

        //Select2 per la selezione di una proprietà della scheda tecnica
        $(".select2-properties")
            .select2({
                theme: "bootstrap-5",
                templateResult: formatOption,
            })
            .on("select2:select", function (e) {
                var data = e.params.data;
                var rotta = $(this).attr("data-route");
                var modalId = $(this).attr("data-bs-target");
                var modalTitle = $(this).attr("data-modal-title");

                var productId = $(this).attr("data-product-id");

                var postParams = {
                    rotta: rotta,
                    data: {
                        modalId: modalId,
                        modalTitle: modalTitle,
                        productId: productId,
                        propertyId: data.id,
                    },
                    onSuccess: "showModal",
                    params: {},
                };

                //console.log(postParams);

                uf.callModal(postParams);
            });

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }

        //$(response.modalId).modal('hide');
        /*
        $(response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");
        */
    }

    //Ricarico lista proprietà collegate a prodotto dopo eliminazione
    updateProductpropertiesList(response) {
        $("#product_properties_list").html(response.view);
        $("#documentproperties_list_container").html(response.selectView);
        uf.dataTable("table-paginate");

        function formatOption(listItem) {
            var saved = $(listItem.element).data("saved");
            var bg = saved ? "bg-warning" : "";

            if (!listItem.id) {
                return listItem.text;
            }

            var $item = $(
                '<div class="py-1 ' + bg + '">' + listItem.text + "</div>"
            );
            return $item;
        }

        //Select2 per la selezione di una proprietà della scheda tecnica
        $(".select2-properties")
            .select2({
                theme: "bootstrap-5",
                templateResult: formatOption,
            })
            .on("select2:select", function (e) {
                var data = e.params.data;
                var rotta = $(this).attr("data-route");
                var modalId = $(this).attr("data-bs-target");
                var modalTitle = $(this).attr("data-modal-title");

                var productId = $(this).attr("data-product-id");

                var postParams = {
                    rotta: rotta,
                    data: {
                        modalId: modalId,
                        modalTitle: modalTitle,
                        productId: productId,
                        propertyId: data.id,
                    },
                    onSuccess: "showModal",
                    params: {},
                };

                //console.log(postParams);

                uf.callModal(postParams);
            });
    }

    //Genero revisione
    /*
    updateArticlesList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
    }
    */

    //Chiudo modale motivazioni offerta
    closeAndUpdateOfferList(response) {
        //$("#offerlist").html(response.view);
        //uf.dataTable('table-paginate');

        //console.log(response.message);

        //console.log(response.modelPlural);
        //console.log(response.modelSingular);
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");

            //Redirect dopo 1 secondo
            setTimeout(function () {
                window.location.href = response.redirectAfterClose;
            }, 100);
        }
    }

    /*
     *********************************************************************
     *
     * OFFERS
     *
     *********************************************************************
     */
    //Filtro prodotti per applicazione - Ricarico materiali e prodotti
    filterProductsList(response) {
        //console.log(response);
        //console.log(response.insulationTypeId);
        $("#products_container").html(response.view);

        if (response.reloadFilters) {
            //console.log('ricarico filtri');
            $("#filterBlock").html(response.filterView);
        }
        $("#materialgroups_container").html(response.materialView);
    }

    //Filtro prodotti per materiale - Ricarico prodotti
    materialProductsList(response) {
        //console.log(response);
        //console.log(response.insulationTypeId);
        $("#products_container").html(response.view);
    }

    //Aggiorno pagina genera revisione
    updateLinkContainer(response) {
        //console.log(response.generateRevision);
        $("#linkPreview").addClass("d-flex");
        $("#linkPreview .alertcontent").html(
            "revisione generata. Nome del file: " + response.filename
        );
        $("#linkPreview").fadeIn("slow");
    }

    //Usercities - Update region
    updateUsercityContent(response) {
        //console.log(response);
        $("#btn-" + response.regionName + "-tab").html(response.buttonView);
        $("#usercity-" + response.regionName).html(response.view);
        uf.dataTable("table-paginate");
        uf.jqMultiselect("multiselect");
    }

    //saveUserCity - Update city
    saveUserCity(response) {
        //console.log(response.checked);
    }

    //Zonecities - Update region
    updateZonecityContent(response) {
        //console.log(response.data);
        $("#btn-" + response.regionName + "-tab").html(response.buttonView);
        $("#zonecity-" + response.regionName).html(response.view);
        uf.dataTable("table-paginate");
        uf.jqMultiselect("multiselect");
        $(".multiselect, select[multiple='multiple']").bsMultiSelect({
            useCssPatch: true, // default, can be ommitted
            cssPatch: {
                // choices - dropdown menu items
                choices: {},
                choice: "pl-2",
            },

            setSelected: function (option /*element*/, value /*true|false*/) {
                var zoneid = option.getAttribute("data-zone-id");
                var regionid = option.getAttribute("data-region-id");
                var provinceid = option.getAttribute("data-province-id");
                var cityid = option.getAttribute("value");
                var rotta = option.getAttribute("data-route");

                if (value) {
                    option.setAttribute("selected", "");
                } else {
                    option.removeAttribute("selected");
                }

                var postParams = {
                    type: "POST",
                    uri: rotta,
                    data: {
                        active: value,
                        regionid: regionid,
                        zoneid: zoneid,
                        provinceid: provinceid,
                        cityid: cityid,
                    },
                    onSuccess: "updateZonecityCities",
                    params: "",
                };

                //console.log(postParams);
                uf.ajaxPost(postParams);

                option.selected = value;
            },
        });
    }

    //Zonecities - Update cities
    updateZonecityCities(response) {
        //console.log(response.checkedP);
        $("#btn-" + response.regionName + "-tab").html(response.view);
        $("#region_" + response.zoneid + "_" + response.regionid).html(
            response.view1
        );
        $("#province_" + response.zoneid + "_" + response.provinceid).html(
            response.view2
        );
        //uf.jqMultiselect('multiselect');

        $(".multiselect, select[multiple='multiple']").bsMultiSelect({
            useCssPatch: true, // default, can be ommitted
            cssPatch: {
                // choices - dropdown menu items
                choices: {},
                choice: "pl-2",
            },

            setSelected: function (option /*element*/, value /*true|false*/) {
                var zoneid = option.getAttribute("data-zone-id");
                var regionid = option.getAttribute("data-region-id");
                var provinceid = option.getAttribute("data-province-id");
                var cityid = option.getAttribute("value");
                var rotta = option.getAttribute("data-route");

                if (value) {
                    option.setAttribute("selected", "");
                } else {
                    option.removeAttribute("selected");
                }

                var postParams = {
                    type: "POST",
                    uri: rotta,
                    data: {
                        active: value,
                        regionid: regionid,
                        zoneid: zoneid,
                        provinceid: provinceid,
                        cityid: cityid,
                    },
                    onSuccess: "updateZonecityCities",
                    params: "",
                };

                //console.log(postParams);
                uf.ajaxPost(postParams);

                option.selected = value;
            },
        });
    }

    // LSPPRJ-77
    showDiscountPreview(response) {
        console.log(response);
        if (response.discount <= 0) {
            // Controllo che il prezzo inserito non sia superiore al prezzo umb
            // Se il prezzo inserito è superiore a quello base lo sconto sarà negativo
            alert("Il prezzo inserito non è valido");
            $(".output-sconto").text(null);
            $("#prezzoInput").val(null);
        } else if (response.discount < 1) {
            // Se lo sconto è compreso tra 0 e 1 viene arrotondato a 1
            $(".output-sconto").text("1%");
        } else {
            const discountValue = response.discount.toFixed(2);
            const discount1Value = response.applied_discount_1;
            const discount2Value = response.applied_discount_2;
            const discount3Value = response.applied_discount_3.toFixed(2);

            $(".output-sconto").text(discountValue + "%");
            $(".output-scala-sconto").text(discount1Value + "%+" + discount2Value + "%+" + discount3Value + "%");
        }
    }

    //Offer - Update cart
    updateCart(response) {
        //console.log(response.view);

        //Ricarico viste sotto modale

        //Ricarico vista tabella articoli se c'è
        if (response.tableView !== undefined) {
            //console.log('aggiorno carrello');
            $("#offerdetails").html(response.tableView);
        }

        //Ricarico vista carrello se c'è
        if (response.view !== undefined) {
            //console.log('aggiorno carrello');
            $("#cart_container").html(response.view);
        }

        //Chiudo modale
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }

        if (response.congruousPrice == 0) {
            console.log("PRICE NOT CONGRUOUS - VOID CONVALIDA");
            $("input[name=convalida]").val(0);
        }
    }

    //Offer - Aggiorno elenco offerte
    updateOffersList(response) {
        console.log(response);
        // $("#offers_container").html(response.view);
        // uf.dataTable("table-paginate");
        location.reload();
    }

    //Offer - Aggiorno dettaglio e chiudo modale
    updateOfferDetail(response) {
        //console.log(response.fields);

        $("#offerdetails").html(response.view);

        //Chiudo modale
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    //Offer - Aggiorno elenco offerte
    updateDiscountList(response) {
        console.log("updateDiscountList");
        // $("#discounts_container").html(response.view);
        // uf.dataTable("table-paginate");
        // $('#holdon-overlay').css('display', 'none');
        $("#discounts_container").html(response.view);
        // console.warn(response.view);
        uf.dataTable("table-paginate");
        console.log($("#holdon-overlay"));
        $("#holdon-overlay").css("display", "none");
    }

    /*
     ******************************************************************************************************************************************************
     *
     * ORDERS
     *
     ******************************************************************************************************************************************************
     */
    updateOrdersList(response) {
        $("#orders_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Messaggio esito invio ordine di nuovo
    updateSendOrderAgain(response) {
        $("#result_messages").html(response.view);
    }

    /*
     ******************************************************************************************************************************************************
     *
     * REPORTS
     *
     ******************************************************************************************************************************************************
     */
    updateReportOffersList(response) {
        $("#offer_table_container").html(response.view);
        $("#offer_info_container").html(response.infoview);
        uf.dataTable("table-paginate");
    }

    updateReportOrdersList(response) {
        $("#table_container").html(response.view);
        $("#orders_container").html(response.infoview);
        //uf.dataTable("table-paginate");
    }

    /*
     *********************************************************************
     *
     * SAMPLES
     *
     *********************************************************************
     */

    updateSampleProductList(response) {
        $("#samples_container").html(response.view);
        //$("#sample_products_container").html(response.itemView);
        uf.dataTable("table-paginate");
        uf.loadTippy();
    }

    //Sample - Aggiorno single_item_blade e carrello
    updateSampleSingleItem(response) {
        //console.log(response.fields);
        $("#sampleitem_" + response.documentId).html(response.itemView);
        $("#cart_container").html(response.view);
        uf.loadTippy();
    }

    //Sample - Aggiorno prodotti su cambio filtro
    updateSampleFilteredProducts(response) {
        //console.log(response.fields);

        $("#products_container").html(response.view);
        uf.loadTippy();
    }

    //Sample - Aggiorno stato richiesta
    updateSampleSatus(response) {
        $("#samplestate_container_" + response.sampleId).html(response.view);
        uf.loadTippy();
    }

    //Sample - Aggiorno data prevista evasione
    updateSampleDispatchdate(response) {
        $("#dispatchdate_container_" + response.sampleId).html(response.view);
        uf.loadTippy();
        uf.myDatePicker("datepicker", 0);
    }

    //Sample - Aggiorno tracking number
    updateSampleTrackingnumber(response) {
        $("#traking_number_container_" + response.sampleId).html(response.view);
        uf.loadTippy();
    }

    //Sample - Inserisco articoli nel carrello da modale, aggiorno carrello e chiudo modale o elimino da carrello
    updateSampleCart(response) {
        //console.log(response.reloadDiv)
        $("#cart_container").html(response.view);
        $("#" + response.reloadDiv).html(response.itemView);

        uf.loadTippy();

        //Chiudo modale
        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
    }

    //Sample - Invio richiesta via mail
    sampleSent(response) {
        // console.log(response);

        $("#mail-result").html(response.view);
    }

    /*
     *********************************************************************
     *
     * SITES
     *
     *********************************************************************
     */

    updateSitesList(response) {
        $("#sites_container").html(response.view);
        uf.dataTable("table-paginate");

        //Tippyjs Tooltip
        tippy(".tippy", {
            allowHTML: true,
            arrow: true,
            theme: "light",
            maxWidth: 500,
        });
    }

    /*
     *********************************************************************
     *
     * TICKETS
     *
     *********************************************************************
     */

    //Chiude la modale per la risposta ad un ticket e aggiorna elenco risposte
    closeModalAnswer(response) {
        $("#tab-conversation").html(response.view);
        $("#aj-ticketTitle").html(response.ticketTitle);

        if (response.modalId != null) {
            var modalId = response.modalId.replace("#", "");

            $("#" + modalId).modal("hide");
            $(".modal-backdrop").remove();
            $(document.body).removeClass("modal-open");
        }
        lape.loadSuggestionS2(".s2autocompleteTickets");
    }

    //Elimina risposta e aggiorna elenco risposte
    updateTicketAnswers(response) {
        $("#tab-conversation").html(response.view);
        lape.loadSuggestionS2(".s2autocompleteTickets");
    }

    //Aggiorno lista ticket
    updateTicketList(response) {
        $("#tickets_container").html(response.view);

        uf.dataTable("table-paginate");
    }

    /*
     ******************************************************************************************************************************************************
     *
     * LAPE BACKEND APP
     *
     ******************************************************************************************************************************************************
     */

    showOfficesView(response) {
        if (response.company_id) {
            $("#company-msg-container div").removeClass("show").fadeOut();
            $("#offices_container").html(response.view);
            console.warn(response.company_id);
            $("#companyid").val(response.company_id);
            uf.loadTippy();
        }
    }

    showReferentsView(response) {
        $("#referents_container").html(response.view);

        uf.loadTippy();
    }

    /*
     *********************************************************************
     *
     * UTILITIES
     *
     *********************************************************************
     */
    //Salvataggio aziende
    updateImportCompanies(response) {
        $("#import_companies_container").html(response.view);
        $("#oldcompanies_container").html(response.tableView);

        uf.dataTable("table-paginate");
    }

    //Salvataggio visite
    updateImportVisits(response) {
        $("#import_visits_container").html(response.view);
        $("#oldvisits_container").html(response.tableView);

        uf.dataTable("table-paginate");
    }

    /*
     ******************************************************************************************************************************************************
     *
     * TESTS
     *
     ******************************************************************************************************************************************************
     */

    //Genera preview codice ticket
    testPreviewTicketCode(response) {
        //console.log(response);
        $("#preview_new_ticket_code").html(response.code);
    }

    //GENERA SCALE SCONTO
    discountscalesUpdateFamiliesList(response) {
        //console.log(response.showListino);
        $("#family").html(response.view);
        //console.log(response.showListino);
    }

    discountscalesUpdateProductsList(response) {
        //console.log(response.showListino);
        var productDisabled = response.familyId > 0 ? false : true;
        var zoneDisabled = response.productId > 0 ? false : true;

        $("#product").prop("disabled", productDisabled);
        $("#discount_scale").prop("disabled", productDisabled);
        $("#zone").prop("disabled", zoneDisabled);
        $("#zone option:first").attr("selected", "selected");
        $("#export-discountscale").prop("disabled", zoneDisabled);
        //$('#discountscales').html('');

        $("#product").html(response.view);

        $("#discount_scale").html(response.view_discounts);

        $("#product option:first-child").attr("selected", "selected");
        $("#product").trigger("change");

        $("#discount_scale option:first-child").attr("selected", "selected");
        $("#discount_scale").trigger("change");
    }

    discountscalesUpdateZoneList(response) {
        //console.log(response.productId);
        //console.log(response.zones);

        var zoneDisabled = $.isEmptyObject(response.zones) ? true : false;

        $("#zone").prop("disabled", zoneDisabled);
        $("#export-discountscale").prop("disabled", zoneDisabled);
        $("#zone").html(response.view);
        //@ts-ignore$('#discountscales').html('');

        $("#zone option:first-child").attr("selected", "selected");
        $("#zone").trigger("change");
    }

    discountscalesEnableButton(response) {
        $("#generate-discountscale").prop("disabled", response.buttonDisabled);

        //Abilito date validità
        var validityDisabled = $.isEmptyObject(response.dates) ? true : false;

        $("#validity").prop("disabled", validityDisabled);
        $("#validity").html(response.view);

        $("#validity option:first-child").attr("selected", "selected");
        $("#validity").trigger("change");
    }

    discountscalesUpdateList(response) {
        //console.log(response.productId);
        $("#discountscales").html(response.view);

        $("#discountscale-table.table-paginate").DataTable({
            //Per far funzionare il popover quando si cambia pagina
            drawCallback: function () {
                // uf.showPopover();
            },
        });
    }

    /*
     *****************************************************************
     * GENERAZIONE LISTINI
     *****************************************************************
     */
    pricelistsUpdateFamiliesList(response) {
        //console.log(response.showListino);
        $("#family").html(response.view);
        //console.log(response.showListino);
    }

    pricelistsUpdateProductsList(response) {
        //console.log(response.showListino);
        var productDisabled = response.familyId > 0 ? false : true;
        var zoneDisabled = response.productId > 0 ? false : true;

        $("#product").prop("disabled", productDisabled);
        $("#zone").prop("disabled", zoneDisabled);
        $("#zone option:first").attr("selected", "selected");
        $("#export-pricelists").prop("disabled", zoneDisabled);
        $("#product").html(response.view);

        $("#product option:first-child").attr("selected", "selected");
        $("#product").trigger("change");

        $("#discount_scale").prop("disabled", productDisabled);
        $("#discount_scale").html(response.view_discounts);
        $("#discount_scale option:first-child").attr("selected", "selected");
        $("#discount_scale").trigger("change");
    }

    pricelistsUpdateZoneList(response) {
        //console.log(response.productId);
        //console.log(response.zones);
        console.log("======================");
        console.log(response);
        console.log("__________________");
        var zoneDisabled = $.isEmptyObject(response.zones) ? true : false;

        $("#zone").prop("disabled", zoneDisabled);
        $("#export-pricelists").prop("disabled", zoneDisabled);
        $("#zone").html(response.view);

        $("#zone option:first-child").attr("selected", "selected");
        $("#zone").trigger("change");
    }

    pricelistsUpdateValidityList(response) {
        //console.log(response.productId);
        //console.log(response.zones);
        console.log("__________________");
        console.log(response);
        console.log("__________________");
        var validityDisabled = $.isEmptyObject(response.validity)
            ? true
            : false;

        $("#validity").prop("disabled", validityDisabled);
        $("#export-pricelists").prop("disabled", validityDisabled);
        $("#validity").html(response.view);

        $("#validity option:first-child").attr("selected", "selected");
        $("#validity").trigger("change");
    }

    pricelistsEnableButton(response) {
        $("#generate-pricelists").prop("disabled", response.buttonDisabled);
    }

    pricelistsUpdateList(response) {
        //console.log(response.productId);
        $("#pricelists").html(response.view);

        uf.dateRange("valid_from", "valid_to");

        //Ricavo pagine datatable
        var oTable = $("#pricelists-table.table-paginate").DataTable({
            //Per far funzionare il popover quando si cambia pagina
            drawCallback: function () {
                // uf.showPopover();
            },
        });

        var allPages = oTable.rows().nodes();

        //Seleziono/Deseleziono tutti. Off-on altrimenti viene chiamato l'evento più volte
        $(document)
            .off("click", ".ajx-selectall")
            .on("click", ".ajx-selectall", function (e) {
                // e.preventDefault();

                var selected = $(this).attr("data-selected");
                // console.log('BBB');
                //console.log(selected);
                //console.log(allPages);
                //if ($(this).hasClass('allChecked')) {
                if (selected == 0) {
                    $(this).attr("data-selected", 1);
                    $(this)
                        .parent()
                        .find("label.form-check-label ")
                        .text("Deseleziona tutto");
                } else {
                    $(this).attr("data-selected", 0);
                    $(this)
                        .parent()
                        .find("label.form-check-label ")
                        .text("Seleziona tutto");
                }

                $(".ajx-checkarticle", allPages).prop(
                    "checked",
                    selected == 0 ? true : false
                );
            });

        //Applico marginalità
        $(document)
            .off("click", ".ajx-marginality")
            .on("click", ".ajx-marginality", function (e) {
                e.preventDefault();

                var percentage = $("#marginality").val();
                var selectedItems = []; //Valori dei checkbox selezionati
                var rotta = $(this).attr("data-route");

                var showListino = $("#showlistino option:checked").val();
                var familyId = $("#family option:checked").val();
                var productId = $("#product option:checked").val();
                var zone = $("#zone option:checked").val();
                var purchasevalidity = $("#validity option:checked").val();

                var family_discount = $("#discount_scale").val();

                //Recuper tutti gli elementi selezionati
                $(".ajx-checkarticle", allPages).each(function (item) {
                    if ($(this).prop("checked")) {
                        selectedItems.push($(this).attr("id"));
                    }
                    //console.log($(this).prop('checked'));
                    //console.log($(this).attr('id'));
                });

                //Salvo nel db e faccio calcoli lato server
                if (selectedItems.length > 0) {
                    //alert('salvo');

                    var postParams = {
                        type: "POST",
                        uri: rotta,
                        data: {
                            selectedItems: selectedItems,
                            marginality: percentage,
                            purchasevalidity: purchasevalidity,
                            showListino: showListino,
                            familyId: familyId,
                            productId: productId,
                            zone: zone,
                            family_discount: family_discount,
                        },
                        onSuccess: "pricelistsUpdateList",
                        params: "",
                    };

                    //console.log(postParams);
                    uf.ajaxPost(postParams);
                }
                //console.log(selectedItems);
            });
    }

    /* *************************************************************************** */
    //IMPORTA ID MAGO
    idmagosUpdateFamiliesList(response) {
        //console.log(response.showListino);
        $("#family").html(response.view);
        //console.log(response.showListino);
    }

    //Salvataggio idmago importati da excel
    updateImportMagos(response) {
        //console.log(response.data);
        $("#saved_container").html(response.view);
    }

    idmagosUpdateProductsList(response) {
        //console.log(response.showListino);

        var productDisabled = response.familyId > 0 ? false : true;
        var zoneDisabled = response.productId > 0 ? false : true;

        $("#product").prop("disabled", productDisabled);
        $("#zone").prop("disabled", zoneDisabled);
        $("#zone option:first").attr("selected", "selected");
        $("#export-discountscale").prop("disabled", zoneDisabled);
        //$('#discountscales').html('');

        $("#product").html(response.view);
    }

    updateMaterialsList(response) {
        $("#materials_container").html(response.view);
        console.warn(response.view);
        uf.dataTable("table-paginate");
    }

    updateCommissionsList(response) {
        // console.log(response);
        $("#commissions_container").html(response.view);
        // console.warn(response.view);
        // uf.dataTable("table-paginate");
        console.log($("#holdon-overlay"));
        $("#holdon-overlay").css("display", "none");
    }

    // Update dopo il caricamento degli Ordini Acquisti Materia Prima (purchase orders)
    updatePurchaseOrders(response) {
        console.warn(response.view);
        $("#purchaseorders_container").html(response.view);
        console.warn(response.view);
        uf.dataTable("table-paginate");
    }

    updateZonesList(response) {
        $("#zones_container").html(response.view);
        //$("#sample_products_container").html(response.itemView);
        uf.dataTable("table-paginate");
        uf.loadTippy();
    }
}

export default postSuccessFunctions; // ES6
