import { locales } from "moment";

class utilitiesFunctions {

    test() {
        alert('utilities: ci sono');
    }

    //Converto stringa in array
    string_to_array(str) {
        if (!str == '') {
            return str.trim().split(',');
        } else {
            return [str];
        }
    };

    //Rempove duplicate form array
    uniq(arr) {
        var result = [];
        $.each(arr, function (i, e) {
            if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;

    }

    //Array di lingue da validare nelle form. Serve per non obbligare a inserire informazioni obbligatorie in tutte le lingue attive
    localesToValidate() {

        var locales = ['it'];

        return locales;

    }


    //Crea l'oggetto di regole e messaggi da passare alla form di validazione
    /*
    Params:
        - commonCssRules, commonRules, commonMessages: campi non localizzati
        - inputClasses: array che contiene l'elenco delle classi associate ai campi da validare con relative regole e messaggi
        - inputIds: array che contiene l'elenco degli id associati ai campi da validare con relative regole e messaggi
    */
    createObjectForValidation(commonCssRules, commonRules, commonMessages, inputClasses = null, inputIds = null) {

        var locales = this.localesToValidate();

        //Campi da validare attraverso la classe
        var cssRules = {};

        //Campi da validare attraverso l'id
        var rules = {};

        //Messaggi di errore
        var messages = {};

        //Per ogni lingua impostata in utilities.js, la aggiungo alla classe e creo array delle regole
        $.each(locales, function (index, locale) {
            if (inputClasses.length > 0) {
                $.each(inputClasses, function (index, input) {
                    cssRules[input.className + '_' + locale] = input.rules; //Aggiungo alle rules quelle per i campi localizzati
                    messages[input.className + '_' + locale] = input.messages; //Aggiungo ai messages quelli per i campi localizzati

                });
            }

            if (inputIds.length > 0) {
                $.each(inputIds, function (index, inputId) {
                    rules[inputId.id + '_' + locale] = inputId.rules; //Aggiungo alle rules quelle per i campi localizzati
                    messages[inputId.id + '_' + locale] = inputId.messages; //Aggiungo ai messages quelli per i campi localizzati
                });
            }

        });

        //Aggiungo i campi localizzati a quelli comuni
        cssRules = $.extend(cssRules, commonCssRules);
        rules = $.extend(rules, commonRules);
        messages = $.extend(messages, commonMessages);


        var results = {
            'cssRules': cssRules,
            'rules': rules,
            'messages': messages,
        }

        return results;

    }

    /*
    ******************************************************************************************************************************************************
     *
     * Filtro ricerca in Cards
     *
     ******************************************************************************************************************************************************
    */
    searchIntoCard(el) {
        // Ripulisco tutte le COL che contengono una CARD.SEARCHABLE e le rendo visibili:
        $('.col .card.searchable').parent().removeClass('d-none');
        // Leggo il valore del campo di ricerca:
        var filter = el.val();
        // Se la ricerca è diversa da "vuoto" applico la ricerca all'interno dell'attributo DATA-SEARCH nel titolo della card:
        if (filter !== '') {
            // Cerco tutte le card che NON HANNO il termine di ricerca:
            var hideCard = $('.searchable-cards').find('.card.searchable .card-body h6:not([data-search *= "' + filter + '"])');
            // Nascondo la COL che contiene la card trovata (che NON HA il termine di ricerca):
            hideCard.parent().parent().parent().addClass('d-none');
        }
    }



    /*
     ******************************************************************************************************************************************************
     *
     * Ajax Post
     *
     ******************************************************************************************************************************************************
     */
    /*
    Parameters
    uf = utilitiesFunctions
    pf = postSuccessFunctions
    postParams = Parametri per la chiamata ajax
    ac = (opz) autocompleteFunctions
    te = (opz) textEditorFunctions
    */
    ajaxPost(postParams) {

        var showLoading = postParams.params.showLoading !== undefined ? postParams.params.showLoading : true;

        if (showLoading) {
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        }

        //alert('stop');
        console.log(postParams.data);
        console.log(postParams);
        console.log(postParams.params);

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: postParams.type,
            url: postParams.uri,
            data: postParams.data,
            success: function (response) {

                // console.log(postParams);
                // console.log(postParams.data['isOffer']);

                //Controllo se devo aprire una modale o fare chiamata ajax standard
                if (postParams.ismodal) {

                    var modalParams = {
                        'modalId': response.modalId,
                        'modalTitle': response.modalTitle,
                        'params': postParams.params,
                    }

                    if(postParams.data['isOffer']!=null && postParams.data['offerdetailId']!=null){
                        uf.showModal(response, modalParams);
                        console.log('isOffer');
                        var shipmentCostRoute = $('#quantity').attr('data-shipment');
                        lape.printShipmentCostOffer(shipmentCostRoute);
                        lape.evaluateMcQntOnLoad();
                    }else{
                        uf.showModal(response, modalParams);
                    }

                    //uf.showModal(response, modalParams);
                } else {
                    //Chiamo dinamicamente la funzione di successo

                    pf.executeSuccess(postParams.onSuccess, response);
                };

                if (showLoading) {
                    uf.loaderStop();
                };

            },
            error: function (e, textStatus, errorThrown) {
                console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                //console.log('Errore...\n' + JSON.stringify(e));

                var err = JSON.parse(e.responseText);
                console.log(err);

                uf.loaderStop();

                //Chiudo il loader in caso di errore
                uf.loader('sk-cube-grid', '<h5>Attenzione, si è verificato un errore nella richiesta...</h5>' + err.message + '<br>in ' + err.file + ' riga ' + err.line);
                setTimeout(function () {
                    uf.loaderStop();
                }, 5000);
            }
        });

    }


    /*
     ******************************************************************************************************************************************************
     *
     * Loader
     *
     ******************************************************************************************************************************************************
     */

    loader(theme, message) {
        HoldOn.open({
            //theme: 'sk-cube-grid',
            //message: '<h4>Loading content</h4>'
            theme: theme,
            message: message,

        })
    }

    loaderStop() {
        HoldOn.close();
    }

    /*
     ******************************************************************************************************************************************************
     *
     * Tooltip
     *
     ******************************************************************************************************************************************************
     */
    loadTooltip() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    loadTippy() {

        // Inizializzo i ToolTip avanzati di Tippy.js:
        tippy('[data-toggle="tooltip"]', {
            content: '[data-tippy-content]',
            theme: 'material',
            inertia: true,
            //animateFill: true,
            //plugins: [animateFill],
        });
    }

    /*
     ******************************************************************************************************************************************************
     *
     * Colorpicker
     *
     ******************************************************************************************************************************************************
     */
    colorPicker(itemContainer, item, bgContainer, saveText, clearText) {
        if ($('.' + itemContainer).length) {
            const pickr = new Pickr({

                // Selector or element which will be replaced with the actual color-picker.
                // Can be a HTMLElement.
                el: '.' + itemContainer,

                // Using the 'el' Element as button, won't replace it with the pickr-button.
                // If true, appendToBody will also be automatically true.
                useAsButton: true,

                // Start state. If true 'disabled' will be added to the button's classlist.
                disabled: false,

                // If set to false it would directly apply the selected color on the button and preview.
                comparison: true,

                // Default color
                default: $('.' + item).val(), //'fff',

                // Optional color swatches. null by default which means it's disabled.
                // Types are all these allowed which can be used in pickr e.g. hex, hsv(a), hsl(a), rgb(a) and cmyk
                swatches: null,

                // Default color representation.
                // Valid options are `HEX`, `RGBA`, `HSVA`, `HSLA` and `CMYK`.
                defaultRepresentation: 'HEX',

                // Option to keep the color picker always visible. You can still hide / show it via
                // 'pickr.hide()' and 'pickr.show()'. The save button keeps his functionality, so if
                // you click it, it will fire the onSave event.
                showAlways: false,

                // Defines a parent for pickr, if useAsButton is true and a parent is NOT defined
                // 'body' will be used as fallback.
                parent: null,

                // Close pickr with this specific key.
                // Default is 'Escape'. Can be the event key or code.
                closeWithKey: 'Escape',

                // Defines the position of the color-picker. Available options are
                // top, left and middle relativ to the picker button.
                // If clipping occurs, the color picker will automatically choose his position.
                position: 'top',

                // Enables the ability to change numbers in an input field with the scroll-wheel.
                // To use it set the cursor on a position where a number is and scroll, use ctrl to make steps of five
                adjustableNumbers: true,

                // Show or hide specific components.
                // By default only the palette (and the save button) is visible.
                components: {

                    preview: true, // Left side color comparison
                    opacity: false, // Opacity slider
                    hue: true, // Hue slider

                    // Bottom interaction bar, theoretically you could use 'true' as propery.
                    // But this would also hide the save-button.
                    interaction: {
                        hex: false, // hex option  (hexadecimal representation of the rgba value)
                        rgba: false, // rgba option (red green blue and alpha)
                        hsla: false, // hsla option (hue saturation lightness and alpha)
                        hsva: false, // hsva option (hue saturation value and alpha)
                        cmyk: false, // cmyk option (cyan mangenta yellow key )

                        input: true, // input / output element
                        clear: true, // Clear button
                        save: true // Save button
                    },
                },

                // Button strings, brings the possibility to use a language other than English.
                strings: {
                    save: saveText, // Default for save button
                    clear: clearText, // Default for clear button
                }
            });

            //Events
            pickr.on('save', cb => {
                var selectedColor = cb.toHEXA().toString()
                $('.' + item).val(selectedColor);
                $('.' + bgContainer).css('background-color', selectedColor);
                //console.log(cb);
            });

        }
    }

    /*
     ******************************************************************************************************************************************************
     *
     * Alert
     *
     ******************************************************************************************************************************************************
     */
    alertBox(alertParams) {
        $.alert({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            icon: 'fa-solid fa-exclamation-triangle',
            //animation: 'top',
            title: alertParams.title,
            content: alertParams.message,
            buttons: {
                confirm: {
                    text: '<i class="fa-solid fa-thumbs-up"></i> ' + alertParams.btnConfirm,
                    btnClass: 'btn btn-success',
                    // action: function () {}
                },
            }
        });

    }

    /*
     ******************************************************************************************************************************************************
     *
     * Confirm
     *
     ******************************************************************************************************************************************************
     */
    confirmBox(confirmParams, postParams) {

        $.confirm({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            icon: 'fa-solid fa-exclamation-triangle',
            //animation: 'top',
            title: confirmParams.title,
            content: confirmParams.message,
            buttons: {
                confirm: {
                    text: '<i class="fa-solid fa-thumbs-up"></i> ' + confirmParams.btnConfirm,
                    btnClass: 'btn btn-success',
                    action: function () {

                        if (postParams.params.openModal) {

                            uf.callModal(postParams);
                        }
                        else {

                            uf.ajaxPost(postParams);
                        }
                    }
                },
                cancel: {
                    text: '<i class="fa-solid fa-times"></i> ' + confirmParams.btnDismiss,
                    btnClass: 'btn btn-danger',
                    action: function () { }
                },
            }
        });

    }


    /*
     ******************************************************************************************************************************************************
     *
     * Dialog box per selezione immagini nella gallery
     *
     ******************************************************************************************************************************************************
     */
    dialogGalleryBox(dialogParams, postParams) {
        $.confirm({
            theme: 'material', // 'material', 'bootstrap'
            type: 'orange',
            //icon: 'fa-solid fa-exclamation-triangle',
            //animation: 'top',
            title: dialogParams.title,
            //containerFluid: true,
            boxWidth: '80%',
            //draggable: true,
            //dragWindowBorder: true,
            useBootstrap: false,
            content: function () {
                var self = this;

                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: postParams.type,
                    url: postParams.uri,
                    data: postParams.data,
                    success: function (response) {
                        self.setContent('<div class="container-fluid">' + response.content + '</div>');
                        loadDropzone('dropzone_images');
                    },
                    error: function (e, textStatus, errorThrown) {
                        console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                        console.log('Errore...\n' + JSON.stringify(e));
                    }
                });
            },

            buttons: {
                confirm: {
                    text: '<i class="fa-solid fa-plus-square"></i> ' + dialogParams.btnConfirm,
                    btnClass: 'btn btn-warning',
                    action: function () {


                        //Aggiungo immagini alla gallery
                        var postImagesParams = {
                            'type': 'POST',
                            'uri': postParams.params.images_route,
                            'data': {
                                'images': $('#selected_files').val(),
                                'galleryId': postParams.params.galleryId,
                            },
                            'onSuccess': 'updateTableItemsContainer',
                            'params': ''
                        }

                        //console.log(postImagesParams);
                        uf.ajaxPost(postImagesParams);
                    }
                },
                cancel: {
                    text: '<i class="fa-solid fa-times"></i> ' + dialogParams.btnDismiss,
                    btnClass: 'btn btn-danger',
                    action: function () { }
                },
            }
        });

    }




    /*
     ******************************************************************************************************************************************************
     *
     * DateTimepicker
     *
     ******************************************************************************************************************************************************
     */

    formatDate(time, format) {
        var t = new Date(time);
        var tf = function (i) {
            return (i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
            switch (a) {
                case 'yyyy':
                    return tf(t.getFullYear());
                    break;
                case 'MM':
                    return tf(t.getMonth() + 1);
                    break;
                case 'mm':
                    return tf(t.getMinutes());
                    break;
                case 'dd':
                    return tf(t.getDate());
                    break;
                case 'HH':
                    return tf(t.getHours());
                    break;
                case 'ss':
                    return tf(t.getSeconds());
                    break;
            }
        })
    };


    //minDate and maxDate only accept y/m/d format

    dateTimeRange(idFrom, idTo, startDate) {

        jQuery.datetimepicker.setLocale('it');

        var configs = {
            dateFormat: 'd/m/Y',
            timeFormat: 'H:m',
            minTime: '09:00',
            maxTime: '20:00',
            step: 30,
        }
        //var mindate = startDate == null ? null : startDate;

        /*
        $('#' + idFrom).datetimepicker({
            format: dateFormat,
            startDate: mindate,
            inline:false,
            minDate:mindate,

        });
        */
        jQuery('#' + idFrom).datetimepicker({
            format: configs.dateFormat + ' ' + configs.timeFormat,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            onSelectDate: function (ct) {


                //jQuery('#hid-' + idTo).val() ? jQuery('#' + idTo).val() : false
                //alert(jQuery('#' + idFrom).val());
                this.setOptions({
                    maxDate: jQuery('#' + idTo).val() ? jQuery('#' + idTo).val() : false
                })
            },
            timepicker: true,
        });
        jQuery('#' + idTo).datetimepicker({
            format: configs.dateFormat + ' ' + configs.timeFormat,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            onSelectDate: function (ct) {

                var startTime = jQuery('#' + idFrom).val();
                var from = new Date(startTime);
                var fromDate = from.getDate() + '/' + from.getMonth() + '/' + from.getFullYear();

                var m = ct.getMonth();
                if (m < 10) {
                    m = '0' + m;
                }
                var toDate = ct.getDate() + '/' + m + '/' + ct.getFullYear();

                //var endTime = new Date(toDate);
                /*
                                if( fromDate < toDate){
                                    alert("fromDate time is lesser");
                                 }
                                 else {
                                    alert("toDate time is lesser");
                                 }
                                 */
                //console.log('startTime: ' + startTime);
                //console.log('fromDate: ' + from.getMonth());
                //console.log('toDate: ' + toDate);


                this.setOptions({
                    minDate: jQuery('#' + idFrom).val() ? jQuery('#' + idFrom).val() : false
                })
            },
            timepicker: true,
        });

    };


    dateRange(idFrom, idTo) {

        jQuery.datetimepicker.setLocale('it');

        var configs = {
            dateFormat: 'd/m/Y',
        }

        jQuery('#' + idFrom).datetimepicker({
            format: configs.dateFormat,
            onSelectDate: function (ct) {

                var from = jQuery('#' + idFrom).val();

                //minDate and maxDate only accept y/m/d format
                var f = moment().format(from);
                var fromValue = moment(f, 'D/MM/YYYY').format('YYYY/MM/D');

                jQuery('#' + idTo).datetimepicker({
                    minDate: fromValue,
                });
                jQuery('#' + idTo).val(f);

            },
            timepicker: false,
        });
        jQuery('#' + idTo).datetimepicker({
            format: configs.dateFormat,
            onShow: function (ct) {

                var from = jQuery('#' + idFrom).val() ? jQuery('#' + idFrom).val() : false
                if (!from == false) {

                    //minDate and maxDate only accept y/m/d format
                    var f = moment().format(from);
                    var fromValue = moment(f, 'D/MM/YYYY').format('YYYY/MM/D');

                    this.setOptions({
                        minDate: fromValue,
                    })

                }
            },
            timepicker: false,
        });

    };

    //inputToWrite = eventuale campo da riempire con la data (es se clicco su icona calendario)
    myDatePicker(className, minDate, startDate) {

        jQuery.datetimepicker.setLocale('it');
        /*
        var forbiddenDays = $('#forbidden-days').attr('data-value');
        forbiddenDays = forbiddenDays.split(',');
        */
        var configs = {
            dateFormat: 'd/m/Y',
            minDate: (typeof (minDate) != "undefined" && minDate !== null) ? minDate : 0,
            startDate: (typeof (startDate) != "undefined" && startDate !== null) ? startDate : 0,
            //forbiddenDays: forbiddenDays,
        }

        jQuery('.' + className).datetimepicker({
            format: configs.dateFormat,
            startDate: configs.startDate,
            minDate: configs.minDate,
            timepicker: false,
            //disabledDates: configs.forbiddenDays,
            formatDate: 'Y-m-d',
            useCurrent: false,

        });



    };

    myTimePicker(className) {

        jQuery.datetimepicker.setLocale('it');
        //var forbiddenDays = $('#forbidden-days').attr('data-value');
        //forbiddenDays = forbiddenDays.split(',');

        //console.log(forbiddenDays);

        var configs = {
            formatTime: 'H:i',
            minTime: '08:00',
            maxTime: false,
            allowBlank: true,
            defaultTime: false,
            hours12: false,
            step: 30,
        }

        jQuery('.' + className).datetimepicker({
            datepicker: false,
            format: configs.formatTime,
            formatTime: configs.formatTime,
            minTime: configs.minTime,
            maxTime: configs.maxTime,
            step: configs.step,
            allowBlank: configs.allowBlank,
            defaultTime: configs.defaultTime,
            hours12: configs.hours12,
            /*
            allowTimes: [
                '12:00', '13:00', '15:00',
                '17:00', '17:05', '17:20', '19:00', '20:00'
            ],
            */
            //disabledDates: configs.forbiddenDays,
        });

    };


    myTimePickerRange(className) {


        var configs = {
            timeFormat: 'H:m',
            minTime: '09:00',
            maxTime: '20:00',
            step: 30,
        }

        jQuery('.' + className).datetimepicker({

            format: configs.timeFormat,
            step: configs.step,
            minTime: configs.minTime,
            maxTime: configs.maxTime,

            onShow: function (ct) {
                this.setOptions({
                    maxTime: jQuery('.to-time').val() ? jQuery('.to-time').val() : false
                })
            },
            datepicker: false
        });

        jQuery('.from-time').datetimepicker({

            format: configs.timeFormat,
            step: configs.step,
            minTime: configs.minTime,
            maxTime: configs.maxTime,

            onShow: function (ct) {
                this.setOptions({
                    minDate: jQuery('.from-time').val() ? jQuery('.from-time').val() : false
                })
            },
            datepicker: false
        });
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Datatable
     *
     ******************************************************************************************************************************************************
     */

    dataTable(className) {

        var rows = typeof ($('.' + className).attr('data-pageLength')) !== 'undefined' ? $('.' + className).attr('data-pageLength') : 50;

        //Setting defaults
        $.extend(true, $.fn.dataTable.defaults, {

            'order': [
                //Numero della colonna dell'ordinamento di default e direzione
                $('.' + className).attr('data-orderBy'),
                $('.' + className).attr('data-orderDirection')
                //3, 'desc'
            ],
            scrollX: $('.' + className).attr('data-scrollX'),
            columnDefs: [

                //Le colonne con questa classe non sono ordinabili
                {
                    targets: 'no-sort',
                    orderable: false
                },

                //L'ordinamento per data non funziona al 100%. Usata la tecnica del campo nascosto con formato data YMd dove serve
                //Le colonne con questa classe vengono formattate in dd/mm/yyyy
                {
                    targets: 'daydate',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY');
                    }
                },

                //Le colonne con questa classe vengono formattate in dd-MM-YYYY H:i
                {
                    targets: 'datetime',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY HH:mm');
                    }
                },

                //Le colonne con questa classe vengono nascoste
                {
                    targets: 'hide',
                    visible: false,
                    searchable: true,
                },
            ],

            orderClasses: false,

            rowReorder: {
                selector: 'th.orderme'
            },

            pageLength: rows,
            /*
            deferRender: true,
            processing: true,
           // serverSide: true,
           */

            /*
                        ajax: {
                            headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            type: 'POST',
                            url: $('.' + className).attr('data-contentRoute'),
            /*
                            success: function (response) {

                                //console.log(response);

                            },
                            error: function (e, textStatus, errorThrown) {
                                var err = JSON.parse(e.responseText);
                                console.log(err);

                            }
            * /
                        },
                        */
            /*
            columns:[
                {data: 'id', name: 'id'},
                {data: 'title', name: 'title'},
                {data: 'action', name: 'action', orderable: false, searchable: false},
            ],
            */


            /*
            //Lazy loading
            processing: true,
            serverSide: true,
            deferLoading: 57,
            */

            //'pageLength': $('.table-paginate').attr('data-pageLength'), //Dà errori su prev e next
            /*
            //Li prende in automatico se specificato l'attributo data-ordering, data-searching etc nella tabella
            ordering: $('.table-paginate').attr('data-ordering'), //true/false
            searching: $('.table-paginate').attr('data-search'), //true/false
            */
            info: false,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
            },
            //Non sente parametri
            //lengthChange: $('.table-paginate').attr('data-lengthChange'), //Visualizza/nasconde dropdown n° record da mostrare
        });


        var dtTable = $('.' + className).DataTable({
            /*
            "deferRender": true,
            "orderClasses": false,
            */
        });

        dtTable.on('row-reorder', function (e, diff, edit) {

            var items = [];

            var rotta = $('.' + className).attr('data-route');
            var postSuccessFunction = $('.' + className).attr('data-success');
            var rows = e.target.rows;
            var family_id = $(this).attr('data-family-id');
            var product_id = $(this).attr('data-product-id');

            /*
            //Inserisco nell'array la nuova posizione degli elementi cambiati
            for (var i = 0, ien = diff.length; i < ien; i++) {
                //var rowData = dtTable.row(diff[i].node).data();
                var itemId = dtTable.row(diff[i].node).id();

                items.push({itemid: itemId, oldPosition: diff[i].oldData, newPosition: diff[i].newData});
            }
            */



            //Inserisco nell'array la nuova posizione di tutti gli elementi
            for (var i = 1; i < rows.length; i++) {
                var itemId = dtTable.row(rows[i]).id();
                items.push({
                    itemid: itemId,
                    newPosition: i
                });

            }

            //console.log(items);

            /*
            $('.' + className).DataTable({
                'destroy': true, //use for reinitialize datatable
             });
            */
            var postParams = {
                'type': 'POST',
                'uri': rotta,
                'data': {
                    'items': items,
                    //Per il sort degli articoli
                    'product_id': product_id,
                    'family_id': family_id,

                },
                'onSuccess': postSuccessFunction,
                'params': '',
            }

            //alert(postSuccessFunction);
            //console.log(items);

            uf.ajaxPost(postParams);

        });
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Bootstrap jquery-multiselect
     *
     ******************************************************************************************************************************************************
     */

    jqMultiselect(className) {

        //https://springstubbe.us/projects/jquery-multiselect/
        $('select[multiple].' + className).multiselect({
            //columns  : 3,
            search: true,
            selectAll: true,
            texts: {
                placeholder: 'Cerca',
                search: 'Cerca',
                selectAll: 'Seleziona tutto',
                unselectAll: 'Deseleziona tutto',
                maxHeight: 400,
                maxWidth: 100,
                //showCheckbox: false,
            },
            onOptionClick: function (element, option) {


                //Passo eventuale funzione al click (es: utenti-città salvo città sulla selezione)
                if (typeof ($(element).attr('data-success')) !== 'undefined') {
                    var thisOpt = $(option);

                    var division = $('input[name=division]').val();

                    var route = $(element).attr('data-route');
                    var successFunction = $(element).attr('data-success');

                    var checked = thisOpt.prop('checked') ? true : false;
                    var postParams = {
                        'type': 'POST',
                        'uri': route,
                        'data': {
                            //'element': element,
                            'option': thisOpt.val(),
                            'checked': checked,
                            'singleItem': true,
                            'division': division,
                        },
                        'onSuccess': successFunction,
                        'params': '',
                    }

                    //console.log(postParams)

                    uf.ajaxPost(postParams);
                    /*
                    alert(
                        'The Option "' + thisOpt.val() + '" was '
                        + (thisOpt.prop('checked') ? '' : 'de') + 'selected [' + checked + ']'
                    );
                        */
                }
            },
            onSelectAll: function (element, selected) {
                //console.log(element)

                var options = $(element).find('option');
                var ids = [];
                //console.log(options)
                if (selected > 0) {
                    var values = $.map(options, function (option) {
                        ids.push($(option).val())
                    });
                }
                //console.log(ids);

                var route = $(element).attr('data-route');
                var successFunction = $(element).attr('data-success-selectall');

                var postParams = {
                    'type': 'POST',
                    'uri': route,
                    'data': {
                        'selectedcityids': ids,
                        'selected': selected,
                        'singleItem': false,
                    },
                    'onSuccess': successFunction,
                    'params': '',
                }

                //console.log(postParams)

                uf.ajaxPost(postParams);
            }



        });
    };

    /*
     ******************************************************************************************************************************************************
     *
     * Bootstrap BsMultiSelect
     *
     ******************************************************************************************************************************************************
     */
    bsmultiselect(className) {

        //https://github.com/DashboardCode/BsMultiSelect
        $("." + className + ", select[multiple='multiple']").bsMultiSelect({
            useCssPatch: true, // default, can be ommitted
            cssPatch: {
                // choices - dropdown menu items
                choices: {},
                choice: 'pl-2',

                // picks - panel where selected item located
                /*
                picks: {
                    width: '100%',
                    listStyleType: 'none',
                    display: 'flex',
                    flexWrap: 'wrap',
                    height: 'auto',
                    marginBottom: '0'
                },
                */

            }
        });

    }



    /*
     ******************************************************************************************************************************************************
     * DELETE PHISYCALLY COVER IMAGES
     ******************************************************************************************************************************************************
     */

    deleteCoverImage(obj) {

        var id = $(obj).attr('data-id');
        var rotta = $(obj).attr('data-route');
        var filename = $(obj).attr('data-filename');
        var containerDiv = $(obj).attr('data-container-div');
        var inputId = $(obj).attr('data-input-id');
        var fieldId = $(obj).attr('data-field-id');
        var imageholder = $(obj).attr('data-image-holder');

        alert(id);

        var confirmParams = {
            'title': $(obj).attr('data-confirm-title'),
            'message': $(obj).attr('data-confirm-message'),
            'btnConfirm': $(obj).attr('data-btn-confirm'),
            'btnDismiss': $(obj).attr('data-btn-dismiss'),
        }

        var postParams = {
            'type': 'POST',
            'uri': rotta,
            'data': {
                'id': id,
                'filename': filename,
                'containerDiv': containerDiv,
                'inputId': inputId,
                'fieldId': fieldId,
                'imageholder': imageholder
            },
            'onSuccess': 'updateCoverContainer',
            'params': '',
        }

        //console.log(postParams);

        uf.confirmBox(confirmParams, postParams);

    };

    /*
     ******************************************************************************************************************************************************
     * OPEN MEDIA WINDOW
     ******************************************************************************************************************************************************
     */
    openMediaWindow(obj) {

        var fieldId = $(obj).attr('data-fieldid');
        var fieldname = $(obj).attr('data-fieldname');

        window.open('/file-manager/fm-button?fieldname=' + fieldname + '&fieldid=' + fieldId, 'fm', 'width=1400,height=800'/*, obj*/).focus();

    };


    /*
     ******************************************************************************************************************************************************
     * ADD MULTIPLE FILE TO HIDDEND FIELD
     ******************************************************************************************************************************************************
     */
    addMultipleAttachments(url, fieldname) {

        //Aggiungo file al campo nascosto
        var alreadyAdded = window.opener.$('#' + fieldname).val(); //Allegati precedenti
        var attachments = alreadyAdded.length > 0 ? alreadyAdded + ',' + url : url; //Immagini precedenti + immagine selezionata
        window.opener.$('#' + fieldname).val(attachments);

        //Aggiungo file anche alla tabella per poterli rimuovere
        var tmp = window.opener.$('#tmp_' + fieldname).html();

        var slug = url.replace(/\s+/g, '-').toLowerCase(); //sostituisco spazio con -
        slug = slug.replace(/\//g, '-').toLowerCase(); //sostituisco / con -
        slug = slug.replace(/\./g, '-').toLowerCase(); //sostituisco . con -

        var badge = '<span id="att_' + slug + '" class="badge bg-info text-white m-2 p-2">' + url + '<a href="#"><i data-fieldname="' + fieldname + '" data-url="' + url + '" data-slug="' + slug + '" class="fa-solid fa-trash-alt ml-2 text-danger delete-att"></i></a></span>';

        window.opener.$('#tmp_' + fieldname).append(badge);

    }

    removeAttachments(obj) {

        //Rimuovo badge file temporaneo e file dal campo nascosto
        var url = $(obj).attr('data-url');
        var slug = $(obj).attr('data-slug');
        var fieldname = $(obj).attr('data-fieldname');

        //Rimuovo dal campo hidden
        var alreadyAdded = $('#' + fieldname).val(); //Allegati precedenti
        var items = alreadyAdded.replace(url, '').toLowerCase(); //sostituisco / con -
        $('#' + fieldname).val(items);

        //Rimuovo badge
        $('#att_' + slug).remove();

    }




    /*
     ******************************************************************************************************************************************************
     * ADD IMAGE TO GALLERY
     ******************************************************************************************************************************************************
     */
    addImage(url, fieldname) {

        var imagetypeId = window.opener.$('#' + fieldname).attr('data-imagetype');
        var oldImages = window.opener.$('#gallerytype_' + imagetypeId).val(); //Immagini precedenti
        var images = oldImages.length > 0 ? window.opener.$('#gallerytype_' + imagetypeId).val() + ',' + url : url; //Immagini precedenti + immagine selezionata
        window.opener.$('#gallerytype_' + imagetypeId).val(images); //Aggiorno campo hidden

        var rotta = window.opener.$('#' + fieldname).attr('data-route');

        var postParams = {
            'type': 'POST',
            'uri': rotta,
            'data': {
                'images': images,
                'imagetypeId': imagetypeId
            },
            'onSuccess': 'updateGalleryList',
            'params': '',
        }

        uf.ajaxPost(postParams);

    }

    /*
     ******************************************************************************************************************************************************
     * RELOAD ORIGINAL FILE
     ******************************************************************************************************************************************************
     */
    //Ricarico la preview del file originale (usato nel filemanager quando si cambia immagine)
    reloadOriginalFile(obj) {

        var isImage = $(obj).attr('data-isimage'); //Per sapere se ricaricare immagine o icona
        var fieldname = $(obj).attr('data-fieldname');
        var fieldId = $(obj).attr('data-fieldid');
        var originalfile = '\/' + $('#' + fieldId).attr('data-original-filename');

        if (isImage) {
            $('#' + fieldId + '_preview').attr('src', originalfile);
        }
        else {
            $('#' + fieldId + '_preview').html('<i class="fa-solid fa-file-pdf fa-4x"></i>');
        }

        $('#' + fieldId).val(originalfile);
        $('#' + fieldId + '_delete_btn').removeClass('d-none'); //Rendo visibile btn delete
        $('#' + fieldId + '_reload_btn').addClass('d-none'); //Rendo invisibile btn reload

    };

    /*
     ******************************************************************************************************************************************************
     * REMOVE FILE
     ******************************************************************************************************************************************************
     */
    //Ricarico la preview del file originale (usato nel filemanager quando si cambia immagine)
    clearUploadedFile(obj) {

        var isImage = $(obj).attr('data-isimage'); //Per sapere se ricaricare immagine o icona
        var fieldname = $(obj).attr('data-fieldname');
        var fieldId = $(obj).attr('data-fieldid');
        var originalfile = $('#' + fieldId).attr('data-original-filename');

        if (isImage) {
            $('#' + fieldId + '_preview').attr('src', null);
        }
        else {
            $('#' + fieldId + '_preview > i.icon_placeholder').addClass('d-none');
        }
        $('#' + fieldId).val(null);
        $('#' + fieldId + '_delete_btn').addClass('d-none'); //Rendo invisibile btn delete

        if (typeof (originalfile) != 'undefined' && originalfile !== null) {
            $('#' + fieldId + '_reload_btn').removeClass('d-none'); //Rendo visibile btn reload
        }
    };


    /*
     ******************************************************************************************************************************************************
     *
     * Uploader
     *
     ******************************************************************************************************************************************************
     */
    uploader(item, uploaderParameters) {
        var configOptions = uploaderParameters.options;
        var fileListContainer = uploaderParameters.fileListContainer;
        var filesTemplate = uploaderParameters.filesTemplate;

        var debugParameters = uploaderParameters.debugParameters;
        var debugContainer = debugParameters.debugContainer;
        var debugTemplate = debugParameters.debugTemplate;


        var uri = $('.' + item).attr('data-route');
        /*
        console.log('uri: ' + uri);
        console.log('options:');
        console.log(configOptions);
        */

        $('.' + item).dmUploader({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: uri,
            allowedTypes: "image/*", //Default "*"
            extFilter: ['jpg', 'jpeg', 'png', 'gif'], //Estenioni consentite
            multiple: false,
            configOptions,

            onDragEnter: function () {
                // Happens when dragging something over the DnD area
                this.addClass('active');
            },
            onDragLeave: function () {
                // Happens when dragging something OUT of the DnD area
                this.removeClass('active');
            },
            onInit: function () {
                // Plugin is ready to use
                ui_add_log('Plugin initialized :)', 'info');
            },
            onComplete: function () {
                // All files in the queue are processed (success or error)
                ui_add_log('All pending tranfers finished');
            },
            onNewFile: function (id, file) {
                // When a new file is added using the file selector or the DnD area
                ui_add_log('New file added #' + id);
                ui_multi_add_file(id, file);
                createThumb(id, file);

            },
            onBeforeUpload: function (id) {
                // about tho start uploading a file
                ui_add_log('Starting the upload of #' + id);
                ui_multi_update_file_progress(id, 0, '', true);
                ui_multi_update_file_status(id, 'uploading', 'Uploading...');
            },
            onUploadProgress: function (id, percent) {
                // Updating file progress
                ui_multi_update_file_progress(id, percent);
            },
            onUploadSuccess: function (id, data) {
                //console.log(data);

                // A file was successfully uploaded
                //ui_add_log('Server Response for file #' + id + ': ' + JSON.stringify(data));
                ui_add_log('Upload of file #' + id + ' COMPLETED', 'success');
                ui_multi_update_file_status(id, 'success', 'Upload Complete');
                ui_multi_update_file_progress(id, 100, 'success', false);

                //Assegno il nome del file come data-attr alla preview del file per l'eliminazione
                //console.log($(this).find('img').attr('id'));
            },
            onUploadError: function (id, xhr, status, message) {
                // Happens when an upload error happens
                ui_multi_update_file_status(id, 'danger', message);
                ui_multi_update_file_progress(id, 0, 'danger', false);
            },
            onFallbackMode: function () {
                // When the browser doesn't support this plugin :(
                ui_add_log('Plugin cant be used here, running Fallback callback', 'danger');
            },
            onFileSizeError: function (file) {
                ui_add_log('File \'' + file.name + '\' cannot be added: size excess limit', 'danger');
            },

        });


        /*
         ******************************************************************************************************************************************************
         * REMOVE UPLOADED ITEMS
         ******************************************************************************************************************************************************
         */
        $(document).on('click', '.uploader-delete-file', function (e) {

            e.preventDefault();
            var filename = $(this).find('img').attr('data-name');
            //console.log($('#' + fileListContainer).attr('data-delete-route'),);

            var confirmParams = {
                'title': $('#' + fileListContainer).attr('data-confirm-title'),
                'message': $('#' + fileListContainer).attr('data-confirm-message'),
                'btnConfirm': $('#' + fileListContainer).attr('data-btn-confirm'),
                'btnDismiss': $('#' + fileListContainer).attr('data-btn-dismiss'),
            }

            var postParams = {
                'type': 'POST',
                'uri': $('#' + fileListContainer).attr('data-delete-route'),
                'data': {
                    'filename': filename,
                },
                'onSuccess': 'updateUploadList',
                'params': '',
            }


            uf.confirmBox(confirmParams, postParams);

        });




        /*
         ******************************************************************************************************************************************************
         * HELPER FUNCTIONS
         ******************************************************************************************************************************************************
         */
        function createThumb(id, file) {
            //Creazione thumbnail preview
            if (typeof FileReader !== "undefined") {
                var reader = new FileReader();
                var img = $('#uploaderFile' + id).find('img');

                //console.log(file);

                reader.onload = function (e) {
                    img.attr('src', e.target.result);
                    img.attr('id', id); //Aggiungo id all'icona remove
                    img.attr('data-name', file.name); //Aggiungo filename all'icona remove
                }
                reader.readAsDataURL(file);
            }
        }


        // Adds an entry to our debug area
        function ui_add_log(message, color) {
            var d = new Date();

            var dateString = (('0' + d.getHours())).slice(-2) + ':' +
                (('0' + d.getMinutes())).slice(-2) + ':' +
                (('0' + d.getSeconds())).slice(-2);

            color = (typeof color === 'undefined' ? 'muted' : color);

            var template = $('#' + debugTemplate).text();
            template = template.replace('%%date%%', dateString);
            template = template.replace('%%message%%', message);
            template = template.replace('%%color%%', color);

            $('#' + debugContainer).find('li.empty').fadeOut(); // remove the 'no messages yet'
            $('#' + debugContainer).prepend(template);
        }

        // Creates a new file and add it to our list
        function ui_multi_add_file(id, file) {
            var template = $('#' + filesTemplate).text();
            console.log(template);
            template = template.replace('%%filename%%', file.name);

            template = $(template);
            template.prop('id', 'uploaderFile' + id);
            template.data('file-id', id);

            $('#' + fileListContainer).find('tr.empty').fadeOut(); // remove the 'no files yet'
            $('#' + fileListContainer).prepend(template);
        }

        // Changes the status messages on our list
        function ui_multi_update_file_status(id, status, message) {
            $('#uploaderFile' + id).find('span').html(message).prop('class', 'status text-' + status);
        }

        // Updates a file progress, depending on the parameters it may animate it or change the color.
        function ui_multi_update_file_progress(id, percent, color, active) {
            color = (typeof color === 'undefined' ? false : color);
            active = (typeof active === 'undefined' ? true : active);

            var bar = $('#uploaderFile' + id).find('div.progress-bar');

            bar.width(percent + '%').attr('aria-valuenow', percent);
            bar.toggleClass('progress-bar-striped progress-bar-animated', active);

            if (percent === 0) {
                bar.html('');
            } else {
                bar.html(percent + '%');
            }

            if (color !== false) {
                bar.removeClass('bg-success bg-info bg-warning bg-danger');
                bar.addClass('bg-' + color);
            }
        }


    }

    /*
    |--------------------------------------------------------------------------
    |
    | MODALI
    |
    |--------------------------------------------------------------------------
    */

    //Chiamo la funzione per recuperare il contenuto da inserire nella modale
    callModal(params) {

        var postParams = {
            'type': 'POST',
            'uri': params.rotta,
            'data': params.data,
            'onSuccess': params.onSuccess,
            'params': params.params,
            'ismodal': true,

        };

        //console.log(params);

        uf.ajaxPost(postParams);

    }


    //Mostro modale
    showModal(response, params) {

        //console.log(params.modalId);
        //console.log(params.params);

        var modalId = params.modalId;

        //Hack per boostrap5
        //Poichè aggiungo il # all'id nella select2, se alla s2 arriva un'id con # già incorporato lo tolgo
        modalId = modalId.replace('#', '');

        // console.log(params);

        $('#' + modalId + ' .modal-body').html(response.view);
        $('#' + modalId + ' .modal-footer').html(response.footerView); //Eventale vista da includere nel footer
        //$('#' + modalId + ' .' + params.containerClass).html(response.view);

        /*
                var modalOptions = {
                    backdrop: 'static', //Chiudo la modal solo cliccando su close
                    //keyboard: false,
                };


                var bsModalEl = document.getElementById(modalId);
                var bsModal = new bootstrap.Modal(bsModalEl, modalOptions);
        */
        //bsModal.toggle();
        /*
                var bsModal = new bootstrap.Modal(document.getElementById(modalId), {
                    backdrop: 'static' //Chiudo la modal solo cliccando su close
                });
        */



        $('#' + modalId).modal({
            backdrop: 'static' //Chiudo la modal solo cliccando su close
        });
        $('#' + modalId).modal("show");


        /*
        ***********************************************************
        *
        * PARAMETRI OPZIONALI PER CHIAMARE DEI PLUGIN
        *
        * **********************************************************
        */

        //Tinymce:  Nome della classe a cui applicare il tinymce
        //if (params.params.editorId !== '' || params.params.editorId !== undefined) {
        if (params.params.editorId != null) { //100% equivalent to the more explicit but less concise: variable === undefined || variable === null
            //te.removeAll();
            //te.removeEditor(params.params.editorId);
            te.loadTinyMCEEditor();
        }

        //Datepicker: Nome della classe a cui applicare il datepicker
        //if (params.params.datepickerClass !== '' || params.params.datepickerClass !== undefined) {
        if (params.params.datepickerClass != null) {
            uf.myDatePicker(params.params.datepickerClass);
        }

        //Datatble: Nome della classe della tabella a cui applicare il datatable
        //if (params.params.datatableClass !== '' || params.params.datatableClass !== undefined) {
        if (params.params.datatableClass != null) {
            uf.dataTable(params.params.datatableClass);
        }

        //Select2: Nome della classe a cui applicare la select2
        //if (params.params.select2Class !== '' || params.params.select2Class !== undefined) {
        if (params.params.select2Class != null) {
            uf.loadCitiesS2(params.params.select2Class);
        }

        //Multiselect: Nome della classe a cui applicare il multiselect
        //if (params.params.multiselectClass !== '' || params.params.multiselectClass !== undefined) {
        if (params.params.multiselectClass != null) {

            uf.jqMultiselect(params.params.multiselectClass);
        }
        //S2 per la ricerca di un prodotto
        //if (params.params.s2SearchProducts !== '' || params.params.s2SearchProducts !== undefined) {
        if (params.params.s2SearchProducts != null) {
            uf.s2SearchProducts('.s2autocompleteProduct');
        }

        //Select2 in una modale
        if (params.params.s2Modal !== null || params.params.s2Modal != null) { // <<--- Per qualche motivo, quando si apre una modale, a volte vuole !== altre != ....
            /*
            Parametri per la s2 nella modale:
                - Nome della classe della select a cui associare la s2
                - Id della modale
            */

            uf.s2Modal(params.params.select2InputClass, params.params.select2ModalId);
        }

        //Tippyjs Tooltip
        uf.loadTippy();


    }


    //Chiudo modale
    closeModal(response, params) {

        //console.log(params.modalId);
        //console.log(params);

        var modalId = response.modalId.replace('#', '');

        //alert(modalId);

        //BS 5
        /*
        var bsModalEl = document.getElementById(modalId);
        var bsModal = bootstrap.Modal.getInstance(bsModalEl)

        bsModal.hide(); //Nascondo
        bsModal.dispose(); //Rimuovo istanza
*/

        $(modalId).modal("hide");
        //$(modalId).modal("toggle");

        //$(response.modalId).modal("hide");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");



    }

    /*
    |--------------------------------------------------------------------------
    |
    | TAGS
    |
    |--------------------------------------------------------------------------
    */

    //http://www.dangribbin.co/jquery-tag-this/demo/
    loadTags(className) {
        $('.' + className).tagThis({
            noDuplicates: true,
            email: true,
            defaultText: '',
            callbacks: {
                onChange: function () {
                    var tags = ($(this).data('tags'));
                    var str = '';
                    $.each(tags, function (index, value) {
                        str = str == '' ? value['text'] : str + ';' + value['text'];
                    });

                    $('#hid_' + className).val(str);
                }
            }
        });
    }

    destroyTags(className) {
        $('.' + className).destroy();
    }


    /*
|--------------------------------------------------------------------------
|
| SELECT 2
|
|--------------------------------------------------------------------------
*/

    //Carica elenco città
    loadCitiesS2(element) {

        var type = $(element).attr('data-id');

        //console.log($(element).attr("data-route"));

        $(element)
            .select2({
                minimumInputLength: 3,
                allowClear: true,
                theme: "bootstrap-5",
                placeholder: $(element).attr("data-placeholder"),
                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'search-city':
                                        obj = {
                                            text: item.name + ' [' + item.code + ']',
                                            id: item.id,
                                            type: type,
                                        }
                                        break;
                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on("select2:select", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val(cityId);
                        break;

                }


            })
            .on("select2:clear", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val('');
                        $('#btn-savestep1').prop('disabled', true);

                        break;

                }


            })
    }


    //Select2
    loadSuggestionS2(element) {

        var type = $(element).attr('data-id');

        //console.log($(element).attr("data-route"));

        $(element)
            .select2({
                minimumInputLength: 3,
                allowClear: true,
                theme: "bootstrap-5",
                placeholder: $(element).attr("data-placeholder"),
                selectionCssClass: "bg-dark.lape",
                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'search-product':
                                        obj = {
                                            text: item.name,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;
                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on("select2:open", function (e) {
                document.querySelector('.select2-search__field').focus();
            })


            .on("select2:select", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    /*
                    case 'search-company':
                        var postParams = {
                            'type': 'POST',
                            'uri': $(element).attr("data-offices-route"),
                            'data': {
                                'companyId': data.id,
                            },
                            'onSuccess': 'showOfficesView',
                            'params': '',
                        }
                        uf.ajaxPost(postParams);

                        $('#companyid').val(data.id);
                        break;
                        */
                    case 'search-product':
                        var postParams = {
                            'type': 'POST',
                            'uri': $(element).attr("data-product-route"),
                            'data': {
                                'productId': data.id,
                                'pageId': $(element).attr("data-page-id"),
                            },
                            'onSuccess': 'filterProductsList',
                            'params': '',
                        }

                        //console.log(postParams);
                        uf.ajaxPost(postParams);

                        break;

                }


            })


            .on("select2:clear", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'search-product':
                        /*
                                                var cityId = data.id;
                                                $('#cityid').val('');
                                                $('#btn-savestep1').prop('disabled', true);
                        */
                        break;

                }


            })
    }


    /*
    ******************************************************************************************************************************************************
    *
    * SELECT 2 PER COMPITI SPECIFICI
    *
    ******************************************************************************************************************************************************
    */

    //Usata in add/edit article
    s2SearchProducts(element) {

        var type = $(element).attr('data-field-id');

        //console.log($(element).attr("data-route"));


        let found=false;
        let modalId=$(element).closest('.modal');
        if(modalId && modalId.length>0){
            found=true;
            modalId=modalId.attr('id');
        }
        console.log($(element))
        console.log($(element).closest('.modal'))
        console.log(modalId)
        if(found){
            $(element)
            .select2({
                dropdownParent: $('#' + modalId),
                minimumInputLength: 3,
                allowClear: true,
                placeholder: $(element).attr("data-placeholder"),
                theme: "bootstrap-5",

                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        //console.log(data);
                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'product_id':
                                        obj = {
                                            text: item.title,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;
                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on("select2:select", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    case 'product_id':

                        var productId = data.id;
                        $('#productid').val(productId);
                        break;

                }


            })
            .on("select2:clear", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'product_id':

                        $('#productid').val('');

                        break;

                }


            })
        }else{
            $(element)
            .select2({
                // dropdownParent: $('#' + modalId),
                minimumInputLength: 3,
                allowClear: true,
                placeholder: $(element).attr("data-placeholder"),
                theme: "bootstrap-5",

                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        //console.log(data);
                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'product_id':
                                        obj = {
                                            text: item.title,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;
                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on("select2:select", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    case 'product_id':

                        var productId = data.id;
                        $('#productid').val(productId);
                        break;

                }


            })
            .on("select2:clear", function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'product_id':

                        $('#productid').val('');

                        break;

                }


            })
        }


    }


    /*
*****************************************************************************
*
* SELECT2 NELLLA MODALE AGGIUNGI SEDE
*
*****************************************************************************
*/

    s2Modal(element, s2ModalId) {

        //console.log(s2ModalId)
        if (s2ModalId != null) { //<<-- Per qualche motivo, a volte non sente il controllo nella showmodal, quindi devo rifarlo anche qui

            var type = $(element).attr('data-id');

            //Hack per boostrap5
            //Poichè aggiungo il # all'id nella select2, se alla s2 arriva un'id con # già incorporato lo tolgo
            var modalId = s2ModalId.replace('#', '');

            //console.log(modalId);

            $(element)
                .select2({
                    dropdownParent: $('#' + modalId), //Id della modale che contiene la select2. Senza la s2 non funziona
                    minimumInputLength: 3,
                    allowClear: true,
                    placeholder: $(element).attr("data-placeholder"),
                    theme: "bootstrap-5",

                    ajax: {
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                        },
                        url: $(element).attr("data-route"),
                        type: "POST",
                        dataType: "json",
                        data: function (params) {
                            var query = {
                                search: params.term,
                            }

                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {
                            var obj = {};

                            return {
                                results: $.map(data, function (item) {

                                    //console.log(type + 'cccc');

                                    switch (type) {
                                        case 'search-city':
                                            obj = {
                                                text: item.name + ' [' + item.code + ']',
                                                id: item.id,
                                                type: type,
                                            }
                                            break;
                                    }

                                    return obj;
                                })
                            };
                        }
                    },
                })



                .on("select2:select", function (e) {
                    var data = e.params.data; //Dati del record

                    var type = e.params.data.type;


                    switch (type) {
                        case 'search-city':

                            var cityId = data.id;
                            $('#cityid').val(cityId);
                            break;

                    }


                })
                .on("select2:clear", function (e) {
                    var data = e.params.data; //Dati del record

                    var type = e.params.data[0].type;
                    //console.log(type);

                    switch (type) {
                        case 'search-city':

                            var cityId = data.id;
                            $('#cityid').val('');
                            $('#btn-savestep1').prop('disabled', true);

                            break;

                    }


                })
        }
    }



}





export default utilitiesFunctions // ES6
